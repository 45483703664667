import axios from "axios";

import { storeHelper } from "../helpers";

const url = process.env.VUE_APP_BLAST_API_BASE_URL;

const state = {
  games: [],
};

const mutations = {
  SET_GAMES: (state, games) => {
    state.games = games;
  },
}

const getters = {
  games: state => state.games,
};

const actions = {
  async getGames({ commit }, data) {
    try {
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
      
      const games = await axios.get(`${url}/admin/game`, config);
      const { data: gamesData } = games;

      commit('SET_GAMES', gamesData);
  
      return gamesData; 
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  }
};

const games = {
  namespaced: true,
  getters,
  actions,
  mutations,
  state
};

export default games;
