<template>
  <div class="container">
    <ul class="pagination">
      <li class="pagination-item">
        <button type="button" @click="onClickPreviousPage" :disabled="isInFirstPage" class="arrow">
          <font-awesome-icon icon="fa-solid fa-chevron-left" />
        </button>
      </li>

      <li v-for="page in pages" :key="page.name" class="pagination-item">
        <button
          type="button"
          @click="onClickPage(page.name)"
          :disabled="page.isDisabled"
          :class="{ active: isPageActive(page.name) }"
          class="pagination-item-button"
        >
          {{ page.name }}
        </button>
      </li>

      <li class="pagination-item">
        <button type="button" @click="onClickNextPage" :disabled="isInLastPage" class="arrow">
          <font-awesome-icon icon="fa-solid fa-chevron-right" />
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  components: {
    FontAwesomeIcon,
  },
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 10,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    startPage() {
      // if (this.currentPage === 1) {
      //   return 1;
      // }

      // if (this.currentPage === this.totalPages) {
      //   return this.totalPages - this.maxVisibleButtons + 1;
      // }

      // return this.currentPage - 1;

      return 1;
    },
    endPage() {
      // return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);

      return 10;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
    pages() {
      const range = [];

      for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        });
      }

      return range;
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit('changePage', 1);
    },
    onClickPreviousPage() {
      this.$emit('changePage', this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit('changePage', page);
    },
    onClickNextPage() {
      this.$emit('changePage', this.currentPage + 1);
    },
    onClickLastPage() {
      this.$emit('changePage', this.totalPages);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
  },
};
</script>

<style>
.container {
  margin-top: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination {
  width: 100%;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-item button:not(.pagination-item-button) {
  margin-left: 10px;
  margin-right: 10px;
}

.pagination-item-button {
  width: 36px;
  height: 36px;
  border-radius: 500px;
  margin: 0px 9px;
  background-color: #382f6b;
  color: #4a338d;
  border: none;
  cursor: pointer;
}

.active {
  background-color: rgba(63, 47, 170, 0.4);
  color: #ffffff;
}

.arrow {
  width: 36px;
  height: 36px;
  background-color: #382f6b;
  border: none;
  color: #4a338d;
  border-radius: 50%;
  cursor: pointer;
}
</style>
