<template>
  <div class="col-12 mt-3 row">
    <div class="col-4 mt-3 row"></div>

    <div class="col-4 mt-3 row justify-content-center">
      <base-button
        round
        simple
        type="primary"
        class="my-3 mr-1 center"
        style="cursor: pointer width: 60px; height: 50px;"
        @click="step >= 2 && prevStep()"
        :disabled="loading || step < 2"
      >
        <span class="center">prev</span>
      </base-button>
      <base-button
        round
        simple
        type="primary"
        class="my-3 ml-1 center"
        style="cursor: pointer width: 60px; height: 50px;"
        @click="step <= 3 && nextStep()"
        :disabled="loading || step > 3"
      >
        <span class="center">next</span>
      </base-button>
    </div>

    <div class="col-4 mt-3 row justify-content-end">
      <base-button
        v-if="!selectedPool"
        simple
        type="primary"
        class="my-3 center"
        style="cursor: pointer width: 60px; height: 50px;"
        @click="savePool(false)"
        :disabled="loading"
      >
        <span v-if="loading" class="center" style="width: 100%; height: 100%">
          <LoadingComponent />
        </span>
        <span v-else class="center">create</span>
      </base-button>
      <base-button
        v-else
        simple
        type="primary"
        class="my-3 center"
        style="cursor: pointer width: 60px; height: 50px;"
        @click="savePool(true)"
        :disabled="loading"
      >
        <span v-if="loading" class="center">
          <LoadingComponent />
        </span>
        <p v-else class="center">update</p>
      </base-button>
    </div>
  </div>
</template>

<script>
import LoadingComponent from '../../LoaderSpinner.vue';

export default {
  components: {
    LoadingComponent,
  },
  props: {
    step: {
      type: Number,
      default: 1,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    selectedPool: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    prevStep() {
      this.$emit('prevStep');
    },
    nextStep() {
      this.$emit('nextStep');
    },
    savePool(update) {
      this.$emit('savePool', update);
    },
  },
};
</script>

<style lang="" scoped></style>
