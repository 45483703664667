<template>
  <div>
    <div class="p-4 mb-4" style="border: solid; border-color: #344675; border-radius: 12px; border-width: thin">
      <div class="col-12 row">
        <label class="col-12 control-label form-text">Game details</label>
        <div v-for="(item, index) in this.pool?.gameDetails?.fields" :key="index" class="col-6 row form-group">
          <div v-if="item.type == 'boolean'" class="col-12 mx-3 form-check">
            <label :for="item.key" class="form-check-label">
              <input v-model="item.value" :id="item.key" type="checkbox" class="form-check-input" />
              <span class="form-check-sign"></span>
              {{ item.name }}
            </label>
          </div>
          <div v-else class="col-12">
            <label class="control-label">{{ item.name }}</label>
            <input
              v-model="item.value"
              aria-describedby="addon-right addon-left"
              :placeholder="item.name"
              class="form-control"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="p-4 mb-4" style="border: solid; border-color: #344675; border-radius: 12px; border-width: thin">
      <div v-if="isLeaderboard" class="col-6 row">
        <label class="col-12 control-label form-text">Main Template *</label>
        <small v-if="!this.mainTemplates.length" class="mb-2 form-text">
          <p style="color: #f66555">This game hasn't any main template aviable.</p>
        </small>
        <select v-model="pool.mainTemplateId" class="form-control" :disabled="!this.mainTemplates.length">
          <option class="color" v-for="(item, index) in this.mainTemplates" :value="item.reference" :key="index">
            {{ item.name }}
          </option>
        </select>
      </div>

      <div class="col-6 row">
        <label class="col-12 control-label form-text">Details Template *</label>
        <small v-if="!this.detailsTemplates.length" class="mb-2 form-text">
          <p style="color: #f66555">This game hasn't any details template aviable.</p>
        </small>
        <small v-if="!detailsTemplateValid" class="mb-2 form-text" style="color: #f66555">
          You must select a template.
        </small>
        <select v-model="pool.detailsTemplateId" class="form-control" :disabled="!this.detailsTemplates.length">
          <option class="color" v-for="(item, index) in this.detailsTemplates" :value="item.reference" :key="index">
            {{ item.name }}
          </option>
        </select>
      </div>

      <div class="col-6 row">
        <label class="col-12 control-label form-text">Report Template</label>
        <small v-if="!this.reportTemplates.length" class="mb-2 form-text">
          <p style="color: #f66555">This game hasn't any report template aviable.</p>
        </small>
        <select v-model="pool.reportTemplateId" class="form-control" :disabled="!this.reportTemplates.length">
          <option class="color" v-for="(item, index) in this.reportTemplates" :value="item.reference" :key="index">
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="p-4 mb-4" style="border: solid; border-color: #344675; border-radius: 12px; border-width: thin">
      <div class="col-12 row">
        <label class="col-10 control-label form-text">Winners fees *</label>
        <label v-if="!selectedPool" class="col-1 control-label" style="cursor: pointer" @click="addWinnerFee()">
          <font-awesome-icon icon="fa-plus" class="form-text" />
        </label>
        <label v-if="!selectedPool" class="col-1 control-label" style="cursor: pointer" @click="removeWinnerFee()">
          <font-awesome-icon icon="fa-minus" class="form-text" />
        </label>
        <small v-if="!winnersFeesValid" class="col-12 mb-2 form-text" style="color: #f66555">
          This values should add 100%.
        </small>
      </div>
      <div v-for="(item, index) in this.pool.winnersFees" :key="index" class="col-12 row">
        <div class="col-12 row form-group">
          <label class="col-2 form-text center">{{ index + 1 }}.</label>
          <input
            v-model="pool.winnersFees[index]"
            type="number"
            @keyup.enter="addWinnerFee()"
            aria-describedby="addon-right addon-left"
            placeholder="winner fee"
            class="col-10 form-control"
            :disabled="selectedPool"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: '',
  components: {
    FontAwesomeIcon,
  },
  mixins: [],
  props: {
    pool: {
      type: Object,
      default: () => {},
    },
    selectedPool: {
      type: Object,
      default: () => {},
    },
    templates: {
      type: Array,
      default: () => [],
    },
    detailsTemplateValid: {
      type: Boolean,
      default: true,
    },
    winnersFeesValid: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      mainTemplates: [],
      detailsTemplates: [],
      reportTemplates: [],
    };
  },
  computed: {
    isLeaderboard() {
      return this.pool.poolMode === 'LEADERBOARD';
    },
  },
  methods: {
    addWinnerFee() {
      this.pool.winnersFees.push('');
    },
    removeWinnerFee() {
      this.pool.winnersFees.pop();
    },
  },
  mounted() {
    this.gameDetails = [...this.$store.getters['pools/gameDetails']];

    this.mainTemplates = this.templates.filter(template => template.type === 'MAIN');
    this.detailsTemplates = this.templates.filter(template => template.type === 'DETAILS');
    this.reportTemplates = this.templates.filter(template => template.type === 'REPORT');

    // update pool
    if (this.selectedPool) {
      return;
    }

    // new pool
    this.pool.gameDetails = this.gameDetails.find(
      gameDetail =>
        gameDetail.poolModes.includes(this.pool.poolMode) && gameDetail.joinModes.includes(this.pool.joinMode),
    );
  },
};
</script>

<style lang="" scoped></style>
