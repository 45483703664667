<template>
  <div class="col-12 row">
    <div class="col-12 row">
      <div class="col-4 d-flex justify-content-center">
        {{ reportData?.match.p1Nickname }}
      </div>

      <div class="col-4 d-flex justify-content-center">
        {{ reportData?.match.p2Nickname }}
      </div>

      <div class="col-4 d-flex justify-content-center">Details</div>
    </div>

    <div v-for="resultIndex in reportData.match.p1Results.length" class="col-12 row">
      <div class="col-4 d-flex justify-content-center m-2">
        <span
          v-if="reportData.match.p1Results[resultIndex - 1].result === 'W'"
          class="px-1"
          style="font-size: 1em; color: #00f2c3"
        >
          <font-awesome-icon icon="fa-solid fa-check" />
        </span>
        <span v-else class="px-1" style="font-size: 1em; color: #f5503d">
          <font-awesome-icon icon="fa-solid fa-xmark" />
        </span>
      </div>

      <div class="col-4 d-flex justify-content-center m-2">
        <span
          v-if="reportData.match.p2Results[resultIndex - 1].result === 'W'"
          class="px-1"
          style="font-size: 1em; color: #00f2c3"
        >
          <font-awesome-icon icon="fa-solid fa-check" />
        </span>
        <span v-else class="px-1" style="font-size: 1em; color: #f5503d">
          <font-awesome-icon icon="fa-solid fa-xmark" />
        </span>
      </div>
    </div>

    <div class="col-12 mt-5" style="border: solid; border-color: #344675; border-radius: 12px; border-width: thin">
      <div class="col-4 form-group">
        <label class="control-label form-text">Winner *</label>
        <select v-model="genericReport.winner" class="form-control">
          <option class="color" v-for="(item, index) in this.players" :value="item" :key="index">
            {{ item.nick }}
          </option>
        </select>
      </div>

      <div class="col-4 form-group">
        <label class="control-label form-text">Match id</label>
        <input
          v-model="genericReport.matchId"
          aria-describedby="addon-right addon-left"
          placeholder="match id"
          class="form-control"
        />
      </div>

      <div class="col-4 form-group">
        <label class="control-label form-text">Replay URL</label>
        <input
          v-model="genericReport.replayURL"
          aria-describedby="addon-right addon-left"
          placeholder="replay url"
          class="form-control"
        />
      </div>
    </div>

    <div class="col-12 mt-3 row justify-content-end">
      <base-button
        simple
        type="primary"
        class="my-3 center"
        style="cursor: pointer width: 60px; height: 50px;"
        @click="!loading && handleReport()"
        :disabled="loading"
      >
        <span v-if="loading" class="center" style="width: 100%; height: 100%">
          <LoadingComponent />
        </span>
        <p v-else class="center">Report</p>
      </base-button>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import LoadingComponent from '@/components/LoaderSpinner';

export default {
  components: {
    FontAwesomeIcon,
    LoadingComponent,
  },
  props: {
    reportData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      genericReport: {
        matchId: '',
        replayURL: '',
      },
      players: [],
    };
  },
  methods: {
    handleReport() {
      const reportData = { ...this.genericReport };

      this.$emit('reportMatch', reportData);
    },
  },
  async mounted() {
    const { match } = this.reportData;
    const { p1aTag, p1Nickname, p2aTag, p2Nickname } = match;

    this.players = [
      { tag: p1aTag, nick: p1Nickname, winnerPosition: 1 },
      { tag: p2aTag, nick: p2Nickname, winnerPosition: 2 },
    ];
    this.genericReport.winner = this.players[0];
  },
};
</script>

<style>
.color {
  color: #222a42;
}
</style>
