<template>
  <div class="row">
    <div class="col-8">
      <BaseModal
        v-if="showSwapPlayers"
        :show.sync="showSwapPlayers"
        class="modal-detail"
        id="swapPlayers"
        :centered="false"
        :show-close="true"
      >
        <template slot="header">
          <h3 class="modal-title">{{ title }}</h3>
        </template>

        <span v-if="loading">
          <LoadingComponent />
        </span>

        <div v-else class="col-12 row justify-content-center">
          <div class="col-4 row">
            <p>{{ selectedPlayer.nick }}</p>
          </div>

          <div class="col-2">
            <font-awesome-icon icon="fa-solid fa-right-left" />
          </div>

          <div class="col-4 form-group">
            <div class="col-12 form-group">
              <div class="col-12 form-group">
                <select v-model="playerToSwap" class="form-control">
                  <option
                    class="color"
                    v-for="(poolPlayer, index) in this.poolPlayers"
                    :value="poolPlayer"
                    :key="index"
                  >
                    {{ poolPlayer.nick }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 row">
          <div class="col-12 mt-3 row justify-content-end">
            <base-button
              simple
              type="primary"
              class="center"
              style="cursor: pointer width: 60px; height: 40px;"
              @click="swapPlayers(playerToSwap)"
            >
              <p class="center">swap</p>
            </base-button>
          </div>
        </div>
      </BaseModal>
    </div>
  </div>
</template>

<script>
import { BaseModal } from '@/components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import LoadingComponent from '@/components/LoaderSpinner';

export default {
  components: {
    BaseModal,
    FontAwesomeIcon,
    LoadingComponent,
  },
  mixins: [],
  props: {
    poolPlayers: {
      type: Array,
      default: () => [],
    },
    showSwapPlayers: {
      type: Boolean,
      default: false,
    },
    selectedPlayer: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: 'Swap players',
      playerToSwap: {},
    };
  },
  computed: {},
  watch: {},
  mounted() {
    if (this.poolPlayers?.length) {
      this.playerToSwap = this.poolPlayers[0];
    }
  },
  methods: {
    async swapPlayers(playerToSwap) {
      const { nick, aTag } = playerToSwap;
      const playersToSwap = [this.selectedPlayer, { id: aTag, nick }];

      this.$emit('swapPlayers', playersToSwap);
    },
  },
};
</script>
<style lang="" scoped></style>
