<template>
  <div class="col-12 row">
    <div class="col-12 row">
      <template v-for="(component, reportIndex) in reportTemplate.components">
        <div v-if="component.visible" class="col-3 d-flex justify-content-center">
          <span>{{ parseValue(component.value[0].value, opponents) }}</span>
        </div>
      </template>

      <div class="col-3 d-flex justify-content-center">
        <span>Actions</span>
      </div>

      <div v-for="(report, reportIndex) in player.gamesData" class="col-12 row">
        <template v-for="(component, reportIndex) in reportTemplate.components">
          <div v-if="component.visible" class="col-3 form-group">
            <input
              v-model="report[component.value[1].value]"
              aria-describedby="addon-right addon-left"
              type="number"
              :placeholder="component.value[1].placeholder"
              class="form-control text-center"
            />
          </div>
        </template>

        <!-- <div class="col-2 form-group">{{ report.timestamp }}</div> -->

        <div class="col-2 d-flex justify-content-end">
          <font-awesome-icon icon="fa-floppy-disk" class="pointer mt-3 mr-3" @click="updateReport(reportIndex)" />
          <font-awesome-icon
            icon="fa-trash"
            class="pointer mt-3"
            @click="deleteReport(reportIndex)"
            :disabled="loading"
          />
        </div>
      </div>
    </div>

    <div class="col-12 mt-5" style="border: solid; border-color: #344675; border-radius: 12px; border-width: thin">
      <h4 class="pt-3 pb-3">Add a new report</h4>

      <div v-for="(component, reportIndex) in reportTemplate.components" class="col-6 form-group">
        <template v-if="isFullInput(component.key)">
          <label class="control-label form-text">{{ parseValue(component.value[0].value, opponents) }}</label>
          <input
            v-model="playerReport[component.value[1].value]"
            aria-describedby="addon-right addon-left"
            :placeholder="component.value[1].placeholder"
            class="form-control"
          />
        </template>

        <template v-if="isSelect(component.key)">
          <div class="col-6 form-group">
            <label class="control-label form-text">{{ parseValue(component.value, opponents) }}</label>
            <select v-model="playerReport[component.value]" class="form-control">
              <option class="color" v-for="(item, index) in opponents" :key="index" :value="item">
                {{ item.nick }}
              </option>
            </select>
          </div>
        </template>
      </div>
    </div>

    <div class="col-12 mt-3 row justify-content-end">
      <base-button
        simple
        type="primary"
        class="my-3 center"
        style="cursor: pointer width: 60px; height: 50px;"
        @click="!loading && handleReport()"
        :disabled="loading"
      >
        <span v-if="loading" class="center" style="width: 100%; height: 100%">
          <LoadingComponent />
        </span>
        <p v-else class="center">Report</p>
      </base-button>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import LoadingComponent from '@/components/LoaderSpinner';

export default {
  components: {
    FontAwesomeIcon,
    LoadingComponent,
  },
  props: {
    player: {
      type: Object,
      default: () => {},
    },
    reportTemplate: {
      type: Object,
      default: () => {},
    },
    opponents: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      playerReport: {},
      reports: [],
      loading: false,
    };
  },
  watch: {
    player: function (playerValue, oldValue) {
      const reports = playerValue.gamesData;
      const components = this.reportTemplate.components;

      for (const report of reports) {
        for (const component of components) {
          report[component.value[1].value] = this.getValueByPath(report, component.value[1].value);
        }
      }
    },
  },
  methods: {
    isFullInput(key) {
      return key === 'full-input';
    },
    isSelect(key) {
      return key === 'select';
    },
    parseValue(value, obj) {
      const match = value.match(/\${(.*?)}/);

      if (match) {
        const key = match[1];
        const keyToReplace = '${' + key + '}';

        value = value.replace(keyToReplace, obj[key]);
      }

      return value;
    },
    getValueByPath(obj, path) {
      const pathArray = path.split('.');
      let current = obj;

      for (const prop of pathArray) {
        if (current && current.hasOwnProperty(prop)) {
          current = current[prop];
        } else {
          return undefined;
        }
      }

      return current;
    },
    handleReport() {
      const reportData = { ...this.playerReport };

      this.$emit('reportMatch', reportData);
    },
    deleteReport(reportIndex) {
      const report = this.player.gamesData[reportIndex];
      const reportData = {
        ...report,
      };

      this.$emit('deleteReport', reportData);
    },
    updateReport(reportIndex) {
      const reportData = {
        ...this.player.gamesData[reportIndex],
        gameIndex: reportIndex,
      };

      this.$emit('updateReport', reportData);
    },
  },
  async mounted() {},
};
</script>

<style>
.color {
  color: #222a42;
}
</style>
