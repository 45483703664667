import axios from "axios";

import { storeHelper } from "../helpers";

const url = process.env.VUE_APP_BLAST_API_BASE_URL;

const state = {
  translation: {},
  translations: [],
  groups: [
    { key: 'join_pool_error_messages', value: 'join pool error messages' }
  ]
};

const mutations = {
  SET_TRANSLATION: (state, translation) => {
    state.tranlsation = translation;
  },
  SET_TRANSLATIONS: (state, translations) => {
    state.translations = translations;
  },
}

const getters = {
  translations: state => state.translations,
  groups: state => state.groups,
};

const actions = {
  async createTranslation({ commit }, data) {
    try {
        const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
        };

        console.log(data);
    
        const translationResponse = await axios.post(`${url}/admin/translation`, data, config);
        const { data: translationData } = translationResponse;
        const { id: transactinoId } = translationData;
    
        return { error: false, message: transactinoId }; 
    } catch (error) {
        const status = error.response?.status;
        const errorResponse = storeHelper.errorRequest(status, error);

        return errorResponse;
    }
  },
  async getTranslations({ commit }, data) {
    try {
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
      
      const tranlsations = await axios.get(`${url}/admin/translation`, config);
      const { data: translationsData } = tranlsations;
  
      commit('SET_TRANSLATIONS', translationsData);
  
      return translationsData;
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async getTranslation({ commit }, data) {
    try {
      const { translationId } = data;
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
  
      const translation = await axios.get(`${url}/admin/translation/${translationId}`, config);
      const { data: translationData } = translation;

      commit('SET_TRANSLATION', translationData);
  
      return translationData;
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async updateTranslation({ commit }, data) {
    try {
      const { translationId, translation } = data;
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
  
      const translationResponse = await axios.put(`${url}/admin/translation/${translationId}`, translation, config);
      
      return { error: false, message: translationResponse.key}; 
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
};

const translations = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};

export default translations;
