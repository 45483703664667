<template>
  <div v-if="!selectedPool?.players?.data?.length">
    <p class="text-center p-3">There are no players for this pool</p>
  </div>
  <div v-else class="col-12 row mt-3 mb-2 mx-2">
    <LeaderboardResults
      :selectedPool="selectedPool"
      :player="selectedPlayer"
      :opponents="opponents"
      @changeResult="changeResult"
    >
    </LeaderboardResults>

    <div class="table-responsive">
      <BaseTable :data="selectedPool.players.data" :columns="selectedPool.players.columns" thead-classes="text-primary">
        <template slot-scope="{ row }">
          <td>{{ row.id }}</td>
          <td>{{ row.nick }}</td>
          <td>{{ row.created }}</td>
          <td>{{ row.status }}</td>
          <td>
            <FontAwesomeIcon
              icon="fa-solid fa-file-pen"
              @click="canSelfReporting(row) && openResults(row)"
              class="pointer px-1"
              :class="{ disable: !canSelfReporting(row) }"
            />

            <FontAwesomeIcon
              icon="fa-solid fa-trash"
              @click="removePlayer(row)"
              class="pointer px-1"
              :class="{ disable: !canSelfReporting(row) }"
            />
          </td>
        </template>
      </BaseTable>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import LeaderboardResults from '@/components/Pools/Results/LeaderboardResults';
import { BaseTable } from '@/components';

export default {
  components: {
    BaseTable,
    FontAwesomeIcon,
    LeaderboardResults,
  },
  props: {
    selectedPool: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedPlayer: null,
      opponents: [],
    };
  },
  watch: {
    'selectedPool.players.data': function (newValue, oldValue) {
      this.selectedPlayer = this.selectedPool.players.data.find(player => player.id === this.selectedPlayer.id);
      this.opponents = this.loadOpponents();
    },
  },
  methods: {
    canSelfReporting(row) {
      return true;
    },
    loadOpponents() {
      return this.selectedPool.leaderboard
        .filter(player => player.userId !== this.selectedPlayer.id)
        .map(player => ({
          aTag: player.aTag,
          id: player.id,
          image: player.avatarURL,
          nick: player.nick,
          userId: player.userId,
        }));
    },
    openResults(row) {
      this.selectedPlayer = { ...row };
      this.opponents = this.loadOpponents();
    },
    removePlayer(row) {
      this.$emit('removePlayer', row);
    },
    async changeResult() {
      this.$emit('changeResult');
    },
  },
};
</script>
