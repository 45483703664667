<template>
  <div class="my-4">
    <div class="p-4" style="border: solid; border-color: #344675; border-radius: 12px; border-width: thin">
      <div class="col-12 row">
        <div class="col-6 form-group">
          <label class="control-label form-text">Key *</label>
          <small v-if="!keyValid" class="col-12 form-text" style="color: #f66555">this key already exists.</small>
          <input
            v-model="translation.key"
            aria-describedby="addon-right addon-left"
            placeholder="Translation key"
            class="form-control"
          />
        </div>

        <div class="col-12 form-group">
          <label class="control-label form-text">Group *</label>
          <select class="col-6 d-inline form-control" v-model="translation.group" :disabled="loading">
            <option value="null" disabled selected>Select a group</option>
            <option class="color" v-for="group in groups" :key="group.id" :value="group">
              {{ group.value }}
            </option>
          </select>
        </div>

        <label class="control-label form-text">Values</label>
        <div class="col-12 form-group">
          <label class="control-label form-text">English *</label>
          <input
            v-model="translation.value.en"
            aria-describedby="addon-right addon-left"
            placeholder="English translation"
            class="form-control"
          />
        </div>

        <div class="col-12 form-group">
          <label class="control-label form-text">Spanish</label>
          <input
            v-model="translation.value.es"
            aria-describedby="addon-right addon-left"
            placeholder="Spanish translation"
            class="form-control"
          />
        </div>

        <div class="col-12 form-group">
          <label class="control-label form-text">Portuguese</label>
          <input
            v-model="translation.value.pt"
            aria-describedby="addon-right addon-left"
            placeholder="Portuguese translation"
            class="form-control"
          />
        </div>

        <div class="col-12 form-group mt-3">
          <div class="col-3 form-check">
            <label for="translation-active" class="form-check-label">
              <input v-model="translation.active" type="checkbox" class="form-check-input" />
              <span class="form-check-sign"></span>
              <span @click="translation.active = !translation.active" class="form-text">Active</span>
            </label>
          </div>

          <div class="col-3 form-check">
            <label for="translation-translated" class="form-check-label">
              <input v-model="translation.translated" type="checkbox" class="form-check-input" />
              <span class="form-check-sign"></span>
              <span @click="translation.translated = !translation.translated" class="form-text">Translated</span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 mt-3 row">
      <div class="col-8"></div>

      <div class="col-4 mt-3 row justify-content-end">
        <base-button
          v-if="!hasTranslation"
          simple
          type="primary"
          class="my-3 center"
          style="cursor: pointer width: 60px; height: 50px;"
          @click="saveTranslation(false)"
          :disabled="loading"
        >
          <span v-if="loading" class="center" style="width: 100%; height: 100%">
            <LoadingComponent />
          </span>
          <span v-else class="center">create</span>
        </base-button>
        <base-button
          v-if="hasTranslation"
          simple
          type="primary"
          class="my-3 center"
          style="cursor: pointer width: 60px; height: 50px;"
          @click="saveTranslation(true)"
          :disabled="loading"
        >
          <span v-if="loading" class="center" style="width: 100%; height: 100%">
            <LoadingComponent />
          </span>
          <span v-else class="center">update</span>
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingComponent from '@/components/LoaderSpinner';

export default {
  components: {
    LoadingComponent,
  },
  props: {
    selectedTranslation: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      groups: [],
      keyValid: true,
      loading: true,
      selectedGroup: {},
      translation: {
        active: false,
        group: '',
        key: '',
        timestamp: 0,
        translated: false,
        value: {
          en: '',
          es: '',
          pt: '',
        },
      },
    };
  },
  computed: {
    hasTranslation() {
      return this.selectedTranslation && Object.keys(this.selectedTranslation).length > 0;
    },
  },
  methods: {
    async saveTranslation(update) {
      this.loading = true;

      const { active, group, key, value, timestamp, translated } = this.translation;

      const translation = {
        active,
        group: group.key,
        key,
        value,
        timestamp,
        translated,
      };

      if (update) {
        const data = { translationId: this.translation.id, translation };

        const translationResponse = await this.$store.dispatch('translations/updateTranslation', data);
        const { error: translationError, message: translationMessage } = translationResponse;

        if (translationError) {
          await this.$notify({
            type: 'danger',
            message: `Error creating translation: ${translationMessage}`,
          });

          this.loading = false;
          return;
        }

        await this.$notify({ type: 'success', message: `Success updating translation: ${translationMessage}` });

        this.loading = false;

        return;
      }

      // new translation
      const translationResponse = await this.$store.dispatch('translations/createTranslation', translation);
      const { error: translationError, message: translationMessage } = translationResponse;

      if (translationError) {
        await this.$notify({
          type: 'danger',
          message: `Error creating translation: ${translationMessage}`,
        });

        this.loading = false;
        return;
      }

      await this.$notify({ type: 'success', message: `Success creating translation: ${translationMessage}` });

      this.loading = false;
    },
  },
  mounted() {
    this.loading = true;
    this.groups = this.$store.getters['translations/groups'];

    // update translation
    if (this.hasTranslation) {
      this.translation = this.selectedTranslation;

      this.loading = false;

      return;
    }

    this.loading = false;
  },
};
</script>

<style lang="" scoped></style>
