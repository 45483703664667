<template>
  <div>
    <form>
      <div class="p-4 mb-4">
        <div class="col-12 row">
          <div class="col-4 form-group component-section">
            <label class="control-label form-text">Component</label>
            <select v-model="component.key" class="form-control" @change="changeComponent(component.key)">
              <option class="color" v-for="(item, index) in this.components" :value="item" :key="index">
                {{ item }}
              </option>
            </select>
          </div>

          <div v-if="hasComponentValue" class="col-12 form-group component-section" :class="{ row: isRow }">
            <label class="control-label form-text" :class="{ 'col-10': isRow }">Value</label>

            <template v-if="isRow" class="row">
              <label class="col-1 control-label" style="cursor: pointer" @click="addRow()">
                <font-awesome-icon icon="fa-plus" class="form-text" />
              </label>
              <label class="col-1 control-label" style="cursor: pointer" @click="removeRow()">
                <font-awesome-icon icon="fa-minus" class="form-text" />
              </label>

              <div v-for="(componentValue, index) in component.value" :key="index" class="row col-3">
                <div class="col-12 form-group">
                  <label class="control-label form-text">Key</label>
                  <select
                    v-model="componentValue.key"
                    class="form-control"
                    @change="changeRowComponent(index, componentValue)"
                  >
                    <option class="color" v-for="(item, index) in componentsRow" :value="item" :key="index">
                      {{ item }}
                    </option>
                  </select>
                </div>

                <div class="col-12 form-group">
                  <label class="control-label form-text">Value</label>
                  <template v-if="componentValue.key === 'full-input'">
                    <div v-if="componentValue.value.length" class="col-12 form-group">
                      <label class="control-label form-text">Label</label>
                      <input
                        v-model="componentValue.value[0].value"
                        aria-describedby="addon-right addon-left"
                        placeholder="Component label"
                        class="form-control"
                      />
                    </div>

                    <div v-if="componentValue.value.length" class="col-12 form-group">
                      <label class="control-label form-text">Value</label>
                      <input
                        v-model="componentValue.value[1].value"
                        aria-describedby="addon-right addon-left"
                        placeholder="Component value"
                        class="form-control"
                      />
                    </div>

                    <div v-if="componentValue.value.length" class="col-12 form-group">
                      <label class="control-label form-text">Placeholder</label>
                      <input
                        v-model="componentValue.value[1].placeholder"
                        aria-describedby="addon-right addon-left"
                        placeholder="Component placeholder"
                        class="form-control"
                      />
                    </div>
                  </template>

                  <template v-if="!(componentValue.key === 'full-input')">
                    <input
                      v-model="componentValue.value"
                      aria-describedby="addon-right addon-left"
                      placeholder="Component value"
                      class="form-control"
                    />
                  </template>
                </div>
              </div>
            </template>

            <template v-if="isFullInput">
              <div v-if="component.value.length" class="col-12 form-group">
                <label class="control-label form-text">Label</label>
                <input
                  v-model="component.value[0].value"
                  aria-describedby="addon-right addon-left"
                  placeholder="Component label"
                  class="form-control"
                />
              </div>

              <div v-if="component.value.length" class="col-12 form-group">
                <label class="control-label form-text">Value</label>
                <input
                  v-model="component.value[1].value"
                  aria-describedby="addon-right addon-left"
                  placeholder="Component value"
                  class="form-control"
                />
              </div>

              <div v-if="component.value.length" class="col-12 form-group">
                <label class="control-label form-text">Placeholder</label>
                <input
                  v-model="component.value[1].placeholder"
                  aria-describedby="addon-right addon-left"
                  placeholder="Component placeholder"
                  class="form-control"
                />
              </div>
            </template>

            <template v-if="isTable">
              <label class="col-1 control-label" style="cursor: pointer" @click="addColumn()">
                <font-awesome-icon icon="fa-plus" class="form-text" />
              </label>
              <label class="col-1 control-label" style="cursor: pointer" @click="removeColumn()">
                <font-awesome-icon icon="fa-minus" class="form-text" />
              </label>

              <div v-for="(componentValue, index) in component.value" :key="index" class="row col-12">
                <div class="col-4 form-group">
                  <label class="control-label form-text">Key</label>
                  <input
                    v-model="componentValue.key"
                    aria-describedby="addon-right addon-left"
                    placeholder="Component label"
                    class="form-control"
                  />
                </div>

                <div class="col-4 form-group">
                  <label class="control-label form-text">Value</label>
                  <input
                    v-model="componentValue.value"
                    aria-describedby="addon-right addon-left"
                    placeholder="Component value"
                    class="form-control"
                  />
                </div>
              </div>
            </template>

            <template v-if="!isFullInput && !isRow && !isTable">
              <input
                v-model="component.value"
                aria-describedby="addon-right addon-left"
                placeholder="Component value"
                class="form-control"
              />
            </template>
          </div>

          <div v-if="hasComponentType" class="col-12 row form-group component-section">
            <div class="col-4 form-group component-section">
              <label class="col-4 control-label form-text">Type</label>
              <select v-model="component.type" class="form-control">
                <option class="color" v-for="(item, index) in this.componentsTypes" :value="item" :key="index">
                  {{ item }}
                </option>
              </select>
            </div>
          </div>

          <div v-if="hasComponentPlaceholder" class="col-12 row form-group component-section">
            <label class="col-4 control-label form-text">Placeholder</label>
            <input
              v-model="component.placeholder"
              aria-describedby="addon-right addon-left"
              placeholder="Component value"
              class="form-control"
            />
          </div>

          <div v-if="hasComponentStyle" class="col-12 row form-group component-section">
            <label class="col-4 control-label form-text">Style</label>
            <label class="col-1 control-label" style="cursor: pointer" @click="addComponentStyle()">
              <font-awesome-icon icon="fa-plus" class="form-text" />
            </label>
            <label class="col-1 control-label" style="cursor: pointer" @click="removeComponentStyle()">
              <font-awesome-icon icon="fa-minus" class="form-text" />
            </label>

            <div v-for="(style, index) in component.style" :key="index" class="row col-12">
              <div class="col-4 form-group">
                <label class="control-label form-text">Key</label>
                <input
                  v-model="style.key"
                  aria-describedby="addon-right addon-left"
                  placeholder="Style key"
                  class="form-control"
                />
              </div>

              <div class="col-4 form-group">
                <label class="control-label form-text">Value</label>
                <input
                  v-model="style.value"
                  aria-describedby="addon-right addon-left"
                  placeholder="Style value"
                  class="form-control"
                />
              </div>
            </div>
          </div>

          <div class="col-12 form-check">
            <label @click="component.visible = !component.visible" for="visible" class="form-check-label">
              <input v-model="component.visible" type="checkbox" class="form-check-input" />
              <span class="form-check-sign"></span>
              <span class="form-text">Visible</span>
            </label>
          </div>
        </div>
      </div>

      <div class="col-12 mt-3 row justify-content-end">
        <base-button
          simple
          type="primary"
          class="my-3 center"
          style="cursor: pointer width: 60px; height: 50px;"
          @click="createComponent()"
        >
          <p class="center">Create</p>
        </base-button>
      </div>
    </form>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  components: {
    FontAwesomeIcon,
  },
  mixins: [],
  props: {},
  data() {
    return {
      component: {
        key: '',
        value: '',
        style: [],
        visible: true,
      },
      components: [],
      componentsHasValue: [],
      componentsHasStyle: [],
      componentsHasType: [],
      componentsHasPlaceholder: [],
      componentsTypes: [],
    };
  },
  computed: {
    hasComponentStyle() {
      return this.componentsHasStyle?.includes(this.component.key);
    },
    hasComponentPlaceholder() {
      return this.componentsHasPlaceholder?.includes(this.component.key);
    },
    hasComponentType() {
      return this.componentsHasType?.includes(this.component.key);
    },
    isFullInput() {
      return this.component.key === 'full-input';
    },
    isRow() {
      return this.component.key === 'row';
    },
    isTable() {
      return this.component.key === 'table';
    },
    hasComponentValue() {
      return this.componentsHasValue?.includes(this.component.key);
    },
  },
  watch: {},
  mounted() {
    this.components = [...this.$store.getters['templates/detailComponents']];
    this.componentsRow = [...this.$store.getters['templates/componentsRow']];
    this.componentsHasValue = [...this.$store.getters['templates/componentsHasValue']];
    this.componentsHasStyle = [...this.$store.getters['templates/componentsHasStyle']];
    this.componentsHasType = [...this.$store.getters['templates/componentsHasType']];
    this.componentsHasPlaceholder = [...this.$store.getters['templates/componentsHasPlaceholder']];
    this.componentsTypes = [...this.$store.getters['templates/componentsTypes']];
    this.componentsTypes = [...this.$store.getters['templates/componentsTypes']];

    this.component = { key: this.components[0], value: '', style: [], visible: true };
  },
  methods: {
    changeComponent() {
      if (this.isRow) {
        this.component.value = [{ key: this.components[0], value: '' }];

        return;
      }

      if (this.isTable) {
        this.component.value = [{ key: '', value: '' }];

        return;
      }

      if (this.isFullInput) {
        this.component.value = [
          { key: 'label', value: '' },
          { key: 'input', value: '', type: 'text' },
        ];

        return;
      }

      this.component.value = '';

      return;
    },
    changeRowComponent(index, component) {
      if (component.key === 'full-input') {
        component.value = [
          { key: 'label', value: '' },
          { key: 'input', value: '', type: 'text' },
        ];

        return;
      }

      component.value = '';

      return;
    },
    addRow() {
      this.component.value = [...this.component.value, { key: this.components[0], value: '' }];
    },
    removeRow() {
      this.component.value.pop();
    },
    addColumn() {
      this.component.value = [...this.component.value, { key: '', value: '' }];
    },
    removeColumn() {
      this.component.value.pop();
    },
    addComponentStyle() {
      this.component.style.push({ key: '', value: '' });
    },
    removeComponentStyle() {
      this.component.style.pop();
    },
    createComponent() {
      const newComponent = structuredClone(this.component);

      this.$emit('createComponent', newComponent);
    },
  },
};
</script>
<style lang="" scoped></style>
