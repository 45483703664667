import Vue from 'vue';
import Vuex from 'vuex';

import accounts from "./modules/accounts";
import advertisements from "./modules/advertisements";
import auth from "./modules/auth";
import templates from "./modules/templates";
import games from "./modules/games";
import monitors from "./modules/monitors";
import organizations from "./modules/organizations";
import pools from "./modules/pools";
import statistics from "./modules/statistics";
import teams from "./modules/teams";
import translations from "./modules/translations";
import users from "./modules/users";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    accounts,
    advertisements,
    auth,
    games,
    monitors,
    organizations,
    pools,
    statistics,
    teams,
    templates,
    translations,
    users,
  }
});
