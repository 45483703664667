import axios from "axios";

import { storeHelper } from "../helpers";

const url = process.env.VUE_APP_BLAST_API_BASE_URL;

const actions = {
  async getStatistics({ commit }, data) {
    try {
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
  
      const stats = await axios.get(`${url}/admin/appStats`, config);
      const { data: statsData } = stats;
  
      return statsData; 
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  }
};

const statistics = {
  namespaced: true,
  actions
};

export default statistics;
