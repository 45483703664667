<template>
  <div class="p-4 mb-4" style="border: solid; border-color: #344675; border-radius: 12px; border-width: thin">
    <div class="col-12 row">
      <div class="col-6 form-group">
        <label class="control-label form-text">Name *</label>
        <small v-if="!nameValid" class="col-12 form-text" style="color: #f66555">name can't be empty.</small>
        <input
          v-model="pool.name"
          aria-describedby="addon-right addon-left"
          placeholder="pool name"
          class="form-control"
        />
      </div>

      <div class="col-6 form-group">
        <label class="control-label form-text">Description *</label>
        <small v-if="!descriptionValid" class="col-12 form-text" style="color: #f66555">
          description can't be empty.
        </small>
        <input
          v-model="pool.description"
          aria-describedby="addon-right addon-left"
          placeholder="description"
          class="form-control"
        />
      </div>
    </div>

    <div class="col-12 row">
      <div class="col-6 form-group">
        <label class="control-label form-text">Game *</label>
        <select v-model="pool.game" @change="changeGame()" class="form-control" :disabled="selectedPool">
          <option class="color" v-for="(item, index) in this.games" :value="item" :key="index">
            {{ item.name }}
          </option>
        </select>
      </div>

      <div class="col-6 form-group">
        <label class="control-label form-text">Pool mode *</label>
        <select v-model="pool.poolMode" @change="changeMode()" class="form-control" :disabled="selectedPool">
          <option class="color" v-for="(item, index) in this.poolModes" :value="item" :key="index">
            {{ item }}
          </option>
        </select>
      </div>
    </div>

    <div class="col-12 row">
      <div class="col-6 form-group">
        <label class="control-label form-text">Join mode *</label>
        <select v-model="pool.joinMode" class="form-control" :disabled="selectedPool">
          <option class="color" v-for="(item, index) in this.joinModes" :value="item" :key="index">
            {{ item }}
          </option>
        </select>
      </div>

      <div class="col-6 form-group">
        <label class="control-label form-text">Join button mode *</label>
        <select v-model="pool.joinBtnMode" class="form-control" :disabled="selectedPool">
          <option class="color" v-for="(item, index) in this.joinBtnModes" :value="item" :key="index">
            {{ item }}
          </option>
        </select>
      </div>
    </div>

    <div v-if="selectedPool" class="col-6 form-group">
      <label class="control-label form-text">Pool status *</label>
      <select v-model="pool.poolStatus" class="form-control">
        <option class="color" v-for="(item, index) in this.poolStatus" :value="item" :key="index">
          {{ item }}
        </option>
      </select>
    </div>

    <div class="col-12 row form-group">
      <div class="col-6 form-group">
        <label class="control-label form-text">{{ registrationStartDateTitle }}</label>
        <datepicker v-model="registrationStartDate" placeholder="Start Date" :bootstrap-styling="true" />
        <base-input
          type="time"
          v-model="registrationStartDateTime"
          @input="generateTimestamp(registrationStartDate, registrationStartDateTime, 'registrationStartDate')"
        />
      </div>

      <div class="col-6 form-group">
        <label class="control-label form-text">{{ registrationEndDateTitle }}</label>
        <datepicker v-model="registrationEndDate" placeholder="End Date" :bootstrap-styling="true" />
        <base-input
          type="time"
          v-model="registrationEndDateTime"
          @input="generateTimestamp(registrationEndDate, registrationEndDateTime, 'registrationEndDate')"
        />
      </div>

      <div class="col-6 form-group">
        <label class="control-label form-text">Start date *</label>
        <datepicker v-model="startDate" placeholder="Start Date" :bootstrap-styling="true" />
        <base-input
          type="time"
          v-model="startDateTime"
          @input="generateTimestamp(startDate, startDateTime, 'startDate')"
        />
      </div>

      <div class="col-6 form-group">
        <label class="control-label form-text">End date *</label>
        <datepicker v-model="endDate" placeholder="End Date" :bootstrap-styling="true" />
        <base-input type="time" v-model="endDateTime" @input="generateTimestamp(endDate, endDateTime, 'endDate')" />
      </div>
    </div>

    <div class="col-12 row">
      <div class="col-6 form-group">
        <label class="control-label form-text">Entrance *</label>
        <input
          v-model="pool.entrance"
          type="number"
          aria-describedby="addon-right addon-left"
          placeholder="0"
          class="form-control"
          :disabled="selectedPool"
        />
      </div>

      <div class="col-6 form-group">
        <label class="control-label form-text">Pool prize *</label>
        <input
          v-model="pool.poolPrize"
          type="number"
          aria-describedby="addon-right addon-left"
          placeholder="0"
          class="form-control"
          :disabled="selectedPool"
        />
      </div>
    </div>

    <div class="col-12 row form-group">
      <div class="col-6 form-group">
        <label class="control-label form-text">Min players *</label>
        <input
          v-model="pool.minPlayers"
          type="number"
          aria-describedby="addon-right addon-left"
          placeholder="3"
          class="form-control"
          :disabled="selectedPool"
        />
      </div>

      <div class="col-6 form-group">
        <label class="control-label form-text">Max players *</label>
        <input
          v-model="pool.maxPlayers"
          type="number"
          aria-describedby="addon-right addon-left"
          placeholder="50"
          class="form-control"
        />
      </div>
    </div>

    <div class="col-12 row mt-2 ml-2 form-group">
      <div class="col-3 form-check">
        <label for="is-published" class="form-check-label">
          <input v-model="pool.isPublished" type="checkbox" class="form-check-input" />
          <span class="form-check-sign"></span>
          <span @click="pool.isPublished = !pool.isPublished" class="form-text">Is published</span>
        </label>
      </div>

      <div class="col-3 form-check">
        <label for="is-published" class="form-check-label">
          <input v-model="pool.showInHome" type="checkbox" class="form-check-input" />
          <span class="form-check-sign"></span>
          <span @click="pool.showInHome = !pool.showInHome" class="form-text">Show in home</span>
        </label>
      </div>

      <div class="col-3 form-check">
        <label for="do-not-finish" class="form-check-label">
          <input v-model="pool.doNotFinish" type="checkbox" class="form-check-input" />
          <span class="form-check-sign"></span>
          <span @click="pool.doNotFinish = !pool.doNotFinish" class="form-text">Do not finish</span>
        </label>
      </div>

      <div class="col-3 form-check">
        <label for="is-paid" class="form-check-label">
          <input v-model="pool.isPaid" type="checkbox" class="form-check-input" />
          <span class="form-check-sign"></span>
          <span @click="pool.isPaid = !pool.isPaid" class="form-text">Is paid</span>
        </label>
      </div>
    </div>

    <div class="col-12 row mt-2 ml-2 form-group">
      <div class="col-3 form-check">
        <label for="enable-join-req-data" class="form-check-label">
          <input v-model="enableJoinReqData" type="checkbox" class="form-check-input" />
          <span class="form-check-sign"></span>
          <span @click="changeEnableJoinReqData()" class="form-text"> Enable join required data </span>
        </label>
      </div>

      <div class="col-3 form-check">
        <label for="enable-user-profile-validation" class="form-check-label">
          <input v-model="enableUserProfileValidation" type="checkbox" class="form-check-input" />
          <span class="form-check-sign"></span>
          <span @click="changeEnableUserProfileValidation()" class="form-text">Enable user profile validation</span>
        </label>
      </div>
    </div>

    <div class="col-12 row mt-2 ml-2 form-group">
      <div class="col-3 form-check">
        <input
          type="file"
          id="file"
          ref="detailHeaderFrontImage"
          class="custom-file-input"
          @change="updatefile($event.target.files, 'Front')"
        />
        <base-button simple type="primary" class="my-1 mr-1" style="cursor: pointer" @click="onPickFile('Front')">
          Upload header front image
        </base-button>
      </div>

      <div class="col-3 form-check">
        <input
          type="file"
          id="file"
          ref="detailHeaderBackImage"
          class="custom-file-input"
          @change="updatefile($event.target.files, 'Back')"
        />
        <base-button simple type="primary" class="my-1 mr-1" style="cursor: pointer" @click="onPickFile('Back')">
          Upload header back image
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import LoadingComponent from '@/components/LoaderSpinner';
import Datepicker from 'vuejs-datepicker';

const POOL_MODE = {
  BEST_OF_3: 'BEST_OF_3',
  BEST_OF_5: 'BEST_OF_5',
  BEST_OF_7: 'BEST_OF_7',
  LEADERBOARD: 'LEADERBOARD',
  ROUND_ROBIN: 'ROUND_ROBIN',
  SINGLE_ELIMINATION: 'SINGLE_ELIMINATION', // best of 1
  DOUBLE_ELIMINATION: 'DOUBLE_ELIMINATION', // best of 1 double elimination
  BEST_OF_5_DE: 'BEST_OF_5_DE', // best of 5 double elimination
  BEST_OF_7_DE: 'BEST_OF_7_DE', // best of 7 double elimination
  BEST_OF_3_DE: 'BEST_OF_3_DE', // best of 3 double elimination
};

const POOL_TYPE = {
  LEADERBOARD: 'LEADERBOARD',
  BRACKETS: 'BRACKETS',
};

export default {
  components: {
    LoadingComponent,
    Datepicker,
  },
  props: {
    descriptionValid: {
      type: Boolean,
      default: false,
    },
    nameValid: {
      type: Boolean,
      default: false,
    },
    pool: {
      type: Object,
      default: () => {},
    },
    selectedPool: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      enableJoinReqData: false,
      enableUserProfileValidation: false,
      gameDetail: [],
      games: [],
      joinBtnModes: [],
      joinModes: [],
      poolModes: [],
      poolStatus: [],
      startDate: null,
      endDate: null,
      registrationStartDate: null,
      registrationEndDate: null,
      startDateTime: null,
      endDateTime: null,
      registrationStartDateTime: null,
      registrationEndDateTime: null,
    };
  },
  computed: {
    registrationStartDateTitle() {
      const isLeaderboard = this.isLeaderboardType(this.pool.poolMode);

      return `Registration start date ${isLeaderboard ? '' : '*'}`;
    },
    registrationEndDateTitle() {
      const isLeaderboard = this.isLeaderboardType(this.pool.poolMode);

      return `Registration end date ${isLeaderboard ? '' : '*'}`;
    },
  },
  methods: {
    isLeaderboardType(poolMode) {
      const { LEADERBOARD } = POOL_MODE;

      return this.getPoolType(poolMode) === LEADERBOARD;
    },
    getPoolType(poolMode) {
      const {
        LEADERBOARD,
        ROUND_ROBIN,
        BEST_OF_3,
        BEST_OF_5,
        BEST_OF_7,
        SINGLE_ELIMINATION,
        DOUBLE_ELIMINATION,
        BEST_OF_3_DE,
        BEST_OF_5_DE,
        BEST_OF_7_DE,
      } = POOL_MODE;
      const leaderboardTypes = [LEADERBOARD, ROUND_ROBIN];
      const bracketsTypes = [
        BEST_OF_3,
        BEST_OF_5,
        BEST_OF_7,
        SINGLE_ELIMINATION,
        DOUBLE_ELIMINATION,
        BEST_OF_3_DE,
        BEST_OF_5_DE,
        BEST_OF_7_DE,
      ];

      if (leaderboardTypes.includes(poolMode)) {
        return POOL_TYPE.LEADERBOARD;
      }

      if (bracketsTypes.includes(poolMode)) {
        return POOL_TYPE.BRACKETS;
      }

      return null;
    },
    onPickFile(type) {
      this.$refs[`detailHeader${type}Image`].click();
    },
    setDates(pool) {
      this.registrationStartDate = moment(pool?.registrationStartDate).format('DD MMM YYYY') || null;
      this.registrationStartDateTime = moment(pool?.registrationStartDate).format('hh:mm') || null;

      this.registrationEndDate = moment(pool?.registrationEndDate).format('DD MMM YYYY') || null;
      this.registrationEndDateTime = moment(pool?.registrationEndDate).format('hh:mm') || null;

      this.startDate = moment(pool?.startDate).format('DD MMM YYYY') || null;
      this.startDateTime = moment(pool?.startDate).format('hh:mm') || null;

      this.endDate = moment(pool?.endDate).format('DD MMM YYYY') || null;
      this.endDateTime = moment(pool?.endDate).format('hh:mm') || null;
    },
    async changeGame() {
      this.$emit('updateTemplates');
    },
    async changeMode() {
      this.$emit('updateTemplates');
    },
    changeEnableJoinReqData() {
      this.enableJoinReqData = !this.enableJoinReqData;

      this.$emit('enableJoinReqData', this.enableJoinReqData);
    },
    changeEnableUserProfileValidation() {
      this.enableUserProfileValidation = !this.enableUserProfileValidation;

      this.$emit('enableUserProfileValidation', this.enableUserProfileValidation);
    },
    generateTimestamp(date, time, key) {
      if (date && time) {
        this.pool[key] = moment(date).format('YYYY-MM-DD') + ' ' + time;
      }
    },
    async updatefile(fileList, type) {
      if (!fileList.length) {
        return;
      }

      const file = fileList[0];
      const fileName = file.name.split('.')[0].replace(/ /g, '-');
      const fullName = `${fileName}-${String(type).toLowerCase()}`;
      const formData = new FormData();

      this.loading = true;
      formData.append(fullName, file);
      formData.append('type', 'pool');

      const uploadFileResponse = await this.$store.dispatch('pools/uploadFile', formData);

      if (uploadFileResponse?.error) {
        await this.$notify({
          type: 'danger',
          message: `Error updating ad image: ${uploadFileResponse?.message}`,
        });

        this.loading = false;
        return;
      }

      await this.$notify({
        type: 'success',
        message: `Header ${String(type).toLowerCase()} image uploaded successfully!`,
      });

      this.pool[`detailHeader${type}Image`] = uploadFileResponse.url;
      this.loading = false;
    },
  },
  async mounted() {
    this.games = [...this.$store.getters['games/games']];
    this.joinBtnModes = [...this.$store.getters['pools/joinBtnModes']];
    this.joinModes = [...this.$store.getters['pools/joinModes']];
    this.poolModes = [...this.$store.getters['pools/poolModes']];
    this.poolStatus = [...this.$store.getters['pools/poolStatus']];
    this.gameDetails = [...this.$store.getters['pools/gameDetails']];

    if (this.$route.params.poolId != 'new') {
      this.setDates(this.selectedPool);
    } else {
      this.registrationStartDate = moment().format('DD MMM YYYY') || null;
      this.registrationStartDateTime = moment().format('hh:mm') || null;

      this.registrationEndDate = moment().add().format('DD MMM YYYY');
      this.registrationEndDateTime = moment().add(10, 'minutes').format('hh:mm');

      this.startDate = moment().add(1, 'day').format('DD MMM YYYY');
      this.startDateTime = moment().format('hh:mm');

      this.endDate = moment().add(1, 'day').format('DD MMM YYYY');
      this.endDateTime = moment().add(10, 'minutes').format('hh:mm');
    }
  },
};
</script>

<style scoped>
.form-text {
  color: white;
  font-size: 14px !important;
}
.color {
  color: #222a42;
}
</style>
