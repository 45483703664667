import axios from "axios";
import * as moment from "moment";

import { storeHelper } from "../helpers";

const url = process.env.VUE_APP_BLAST_API_BASE_URL;

const getters = {
  ad: () => ({
    active: false,
    description: '',
    endDate: moment().format('YYYY-MM-DD HH:mm'),
    largeImage: null,
    smallImage: null,
    startDate: moment().format('YYYY-MM-DD HH:mm'),
    title: '',
    type: { name: 'DASHBOARD' },
    values: [],
  }),
}

const actions = {
  async createAdvertisement({ commit }, data) {
    try {
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };

      const ad = await axios.post(`${url}/admin/advertisement`, data, config);
      const { data: adData } = ad;
  
      return adData;
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async getAdvertisements({ commit }, data) {
    try {
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
      
      const ads = await axios.get(`${url}/admin/advertisement`, config);
      const { data: adsData } = ads;
  
      return adsData;
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async updateAdvertisementImage({ commit }, data) {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/multipart/form-data',
          token: localStorage.getItem("auth_access_token")
        }
      };

      const adImage = await axios.post(`${url}/admin/file`, data, config);
      const { data: adImageData } = adImage;
  
      return adImageData;
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async updateAd({ commit }, data) {
    try {
      const { reference: adId, title } = data;
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
      console.log('data', data);
  
      const response = await axios.put(`${url}/admin/advertisement/${adId}`, data, config);
      const { data: resData } = response;
  
      return { error: false, message: title }; 
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
};

const advertisements = {
  namespaced: true,
  getters,
  actions
};

export default advertisements;
