<template>
  <div class="col-12 row">
    <BaseModal :show.sync="showConfirm" class="modal-detail" id="confirmModal" :centered="false" :show-close="true">
      <template>
        <div class="col-12 form-group">
          <h3 class="modal-title">Confirm move players to pool {{ selectedPoolTo?.name }}</h3>
        </div>

        <div class="col-12 form-group my-4">
          <p>Do you want to delete the user activity & statistics from the origin pools?</p>
          <div class="col-3 form-check">
            <label
              for="translation-active"
              @click="removeOriginData = !removeOriginData"
              class="form-check-label d-flex align-items-center"
            >
              <input v-model="removeOriginData" type="checkbox" class="form-check-input" />
              <span class="form-check-sign"></span>
              <p class="form-text">Remove origin data</p>
            </label>
          </div>
        </div>

        <div class="col-12 form-group">
          <div class="col-12 mt-3 row justify-content-end">
            <base-button
              simple
              type="primary"
              class="center"
              style="cursor: pointer width: 60px; height: 40px;"
              @click="movePlayers(true)"
            >
              <p class="center">Confirm</p>
            </base-button>
          </div>
        </div>
      </template>
    </BaseModal>

    <div class="col-5 form-group">
      <div class="col-12 form-group">
        <label class="control-label form-text">Search a pool name to move from</label>

        <div class="col-12 my-2 row">
          <div class="col-9">
            <input
              v-model="poolFromSearch"
              aria-describedby="addon-right addon-left"
              placeholder="pool name"
              class="form-control"
            />
          </div>

          <div class="col-3">
            <base-button
              simple
              type="primary"
              class="center"
              style="cursor: pointer width: 60px; height: 36px;"
              @click="searchPools(poolFromSearch, POOL_TYPES.FROM)"
            >
              <p class="center">search</p>
            </base-button>
          </div>
        </div>

        <span v-if="loadingFrom" class="center my-5" style="width: 100%; height: 100%">
          <LoadingComponent />
        </span>

        <div v-if="!loadingFrom" class="col-12 form-group">
          <select v-model="selectedPoolFrom" class="form-control">
            <option class="color" v-for="(pool, index) in this.poolsFrom" :value="pool" :key="index">
              {{ pool.id }} | {{ pool.name }}
            </option>
          </select>
        </div>

        <div v-if="!loadingFrom" class="col-12 form-group">
          <div class="py-4 my-4" style="border: solid; border-color: #344675; border-radius: 12px; border-width: thin">
            <span v-if="!selectedPoolFrom?.leaderboard.length" class="center">The are no players for this pool</span>
            <div v-for="(player, index) in selectedPoolFrom?.leaderboard" :key="index" class="col-12 row player">
              <div class="col-10">
                {{ player.nick }}
              </div>
              <div class="col-2">
                <base-checkbox v-model="player.move" :inline="inLine"> </base-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-2 d-flex justify-content-center">
      <font-awesome-icon
        :disabled="!selectedPoolTo || loadingFrom || loadingTo"
        icon="fa-solid fa-arrow-right"
        @click="movePlayersTo()"
        class="pointer p-3 mt-5"
        :class="{
          pointer: selectedPoolTo && !loadingFrom && !loadingTo,
          white: selectedPoolTo && !loadingFrom && !loadingTo,
        }"
        style="border: solid; border-color: #344675; border-radius: 12px; border-width: thin"
      />
    </div>

    <div class="col-5 form-group">
      <div class="col-12 form-group">
        <label class="control-label form-text">Search a pool name to move</label>

        <div class="col-12 my-2 row">
          <div class="col-9">
            <input
              v-model="poolToSearch"
              aria-describedby="addon-right addon-left"
              placeholder="pool name"
              class="form-control"
            />
          </div>

          <div class="col-3">
            <base-button
              simple
              type="primary"
              class="center"
              style="cursor: pointer width: 60px; height: 36px;"
              @click="searchPools(poolToSearch, POOL_TYPES.TO)"
            >
              <p class="center">search</p>
            </base-button>
          </div>
        </div>

        <span v-if="loadingTo" class="center my-5" style="width: 100%; height: 100%">
          <LoadingComponent />
        </span>

        <div v-if="!loadingTo" class="col-12 form-group">
          <select v-model="selectedPoolTo" class="form-control">
            <option class="color" v-for="(selectedPoolTo, index) in this.poolsTo" :value="selectedPoolTo" :key="index">
              {{ selectedPoolTo.name }}
            </option>
          </select>
        </div>

        <div v-if="!loadingTo" class="col-12 form-group">
          <div class="py-4 my-4" style="border: solid; border-color: #344675; border-radius: 12px; border-width: thin">
            <span v-if="!selectedPoolTo?.leaderboard.length" class="center">The are no players for this pool</span>
            <div v-for="(player, index) in selectedPoolTo?.leaderboard" :key="index" class="col-12 row player">
              <div class="col-10">
                {{ player.nick }}
              </div>
              <div class="col-2">
                <font-awesome-icon
                  v-if="player.move"
                  icon="fa-solid fa-xmark"
                  @click="removePlayer(index)"
                  class="pointer px-1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 row">
      <div class="col-12 mt-3 row justify-content-end">
        <base-button
          simple
          type="primary"
          class="center"
          style="cursor: pointer width: 60px; height: 40px;"
          @click="movePlayers(false)"
          :disabled="!selectedPoolTo || loadingFrom || loadingTo"
        >
          <p class="center">move</p>
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseModal } from '@/components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import LoadingComponent from '../LoaderSpinner.vue';

export default {
  name: '',
  components: {
    BaseModal,
    FontAwesomeIcon,
    LoadingComponent,
  },
  mixins: [],
  props: {},
  data() {
    return {
      showConfirm: false,
      inLine: true,
      loadingFrom: false,
      loadingTo: false,
      removeOriginData: false,
      POOL_TYPES: {
        FROM: 'from',
        TO: 'to',
      },
      poolFromSearch: '',
      poolToSearch: '',
      selectedPoolFrom: null,
      selectedPoolTo: null,
      poolsFrom: [],
      poolsTo: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    async searchPools(poolSearch, poolType) {
      const data = { searchQuery: poolSearch };

      this.loadingFrom = poolType === this.POOL_TYPES.FROM;
      this.loadingTo = poolType === this.POOL_TYPES.TO;

      await this.$store.dispatch('pools/searchPools', data);

      if (poolType === this.POOL_TYPES.FROM) {
        this.poolsFrom = this.$store.getters['pools/pools'];
        this.poolsFrom.sort((a, b) => {
          a.name >= b.name ? 1 : -1;
        });
      }

      if (poolType === this.POOL_TYPES.TO) {
        this.poolsTo = this.$store.getters['pools/pools'];
        this.poolsTo.sort((a, b) => {
          a.name >= b.name ? 1 : -1;
        });
      }

      this.loadingFrom = false;
      this.loadingTo = false;
    },
    async updatePoolLeaderboard(selectedPool, poolType) {
      const { id: poolId } = selectedPool;
      const data = { poolId: `POOL_${poolId}` };
      const pool = await this.$store.dispatch('pools/getPool', data);

      if (poolType === this.POOL_TYPES.FROM) {
        this.selectedPoolFrom.leaderboard = pool.leaderboard;
      }

      if (poolType === this.POOL_TYPES.TO) {
        this.selectedPoolTo.leaderboard = pool.leaderboard;
      }
    },
    movePlayersTo() {
      const { id: poolIdFrom, leaderboard: playersFrom = [] } = this.selectedPoolFrom || {};
      let { leaderboard: playersTo = [] } = this.selectedPoolTo || {};
      const playersToNicks = playersTo.map(player => player.nick);

      for (const player of playersFrom) {
        if (player.move && !playersToNicks.includes(player.nick)) {
          player.poolIdFrom = poolIdFrom;

          playersTo = [...playersTo, player];
        }
      }

      this.selectedPoolTo.leaderboard = playersTo;
    },
    async movePlayers(confirm) {
      try {
        if (!confirm) {
          this.showConfirm = true;

          return;
        }

        const { id: poolIdTo } = this.selectedPoolTo;
        const playersToMove = this.selectedPoolTo?.leaderboard.filter(player => player.move);
        let poolsToMove = [];

        this.showConfirm = false;
        this.loadingFrom = true;
        this.loadingTo = true;

        for (const playerToMove of playersToMove) {
          const { poolIdFrom, aTag, userId, nick } = playerToMove;

          const poolIdx = poolsToMove.findIndex(poolToMove => poolToMove.poolIdFrom === poolIdFrom);

          if (poolIdx < 0) {
            poolsToMove.push({ poolIdFrom, players: [{ aTag, userId, nick }] });
          } else {
            const poolToMove = poolsToMove[poolIdx];

            poolToMove.players.push({ aTag, userId });
          }
        }

        const data = {
          poolIdTo,
          poolsToMove,
          removeOriginData: this.removeOriginData,
        };

        const movePlayersResponse = await this.$store.dispatch('pools/movePlayers', data);
        const { error: movePlayersError, message: movePlayersMessage } = movePlayersResponse;

        if (movePlayersError) {
          await this.$notify({ type: 'danger', message: `Error moving players: ${movePlayersMessage}` });

          this.removeOriginData = false;
          this.loadingFrom = false;
          this.loadingTo = false;

          return;
        }

        await this.$notify({ type: 'success', message: `Success moving players: ${movePlayersMessage}` });
        await this.updatePoolLeaderboard(this.selectedPoolFrom, this.POOL_TYPES.FROM);
        await this.updatePoolLeaderboard(this.selectedPoolTo, this.POOL_TYPES.TO);

        this.removeOriginData = false;
        this.loadingFrom = false;
        this.loadingTo = false;
      } catch (error) {
        await this.$notify({ type: 'danger', message: `Error moving players: ${error}` });

        this.loadingFrom = false;
        this.loadingTo = false;

        return;
      }
    },
    removePlayer(indexToRemove) {
      this.selectedPoolTo.leaderboard.splice(indexToRemove, 1);
    },
  },
};
</script>
<style lang="scss" scoped>
.white {
  color: white !important;
  border-color: white !important;
}

.player:nth-child(odd) {
  color: white !important;
  background-color: transparent;
  border-radius: 0 0 0px 0px;
  margin: 0px;
  padding: 3px 5px;
}

/* Style for even-numbered items */
.player:nth-child(even) {
  color: white !important;
  background-color: rgb(27, 13, 92) !important;
  border-radius: 0px;
  margin: 0px;
  padding: 3px 5px;
}
</style>
