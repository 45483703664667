<template>
  <div class="p-4 mb-4" style="border: solid; border-color: #344675; border-radius: 12px; border-width: thin">
    <div class="col-12 row">
      <div class="col-6">
        <div class="col-12 form-group">
          <label class="control-label form-text">Entrance token name</label>
          <select
            v-model="pool.entranceTokenName"
            @change="selectEntranceToken(pool.entranceTokenName)"
            class="form-control"
            :disabled="selectedPool"
          >
            <option class="color" v-for="(item, index) in entranceTokens" :value="item.name" :key="index">
              {{ item.name }}
            </option>
          </select>
        </div>

        <div class="col-12 form-group">
          <label class="control-label form-text">Entrance token symbol</label>
          <input
            v-model="pool.entranceTokenSymbol"
            aria-describedby="addon-right addon-left"
            placeholder="MATIC"
            class="form-control"
            :disabled="selectedPool"
          />
        </div>

        <div class="col-12 form-group">
          <label class="control-label form-text">Entrance token address</label>
          <input
            v-model="pool.entranceTokenAddress"
            aria-describedby="addon-right addon-left"
            placeholder="0x0000000000000000000000000000000000000000"
            class="form-control"
            :disabled="selectedPool"
          />
        </div>

        <div class="col-12 form-group">
          <label class="control-label form-text">Entrance token image</label>
          <input
            v-model="pool.entranceTokenImage"
            aria-describedby="addon-right addon-left"
            placeholder="https://storage.googleapis.com/dev-blast.appspot.com/assets/Misc/tokens/matic.png"
            class="form-control"
          />
        </div>
      </div>

      <div class="col-6">
        <div class="col-12 form-group">
          <label class="control-label form-text">Prize token name *</label>
          <select
            v-model="pool.tokenName"
            @change="selectToken(pool.tokenName)"
            class="form-control"
            :disabled="selectedPool"
          >
            <option class="color" v-for="(item, index) in tokens" :value="item.name" :key="index">
              {{ item.name }}
            </option>
          </select>
        </div>

        <div class="col-12 form-group">
          <label class="control-label form-text">Prize token symbol *</label>
          <input
            v-model="pool.tokenSymbol"
            aria-describedby="addon-right addon-left"
            placeholder="MATIC"
            class="form-control"
            :disabled="selectedPool"
          />
        </div>

        <div class="col-12 form-group">
          <label class="control-label form-text">Prize token address *</label>
          <input
            v-model="pool.tokenAddress"
            aria-describedby="addon-right addon-left"
            placeholder="0x0000000000000000000000000000000000000000"
            class="form-control"
            :disabled="selectedPool"
          />
        </div>

        <div class="col-12 form-group">
          <label class="control-label form-text">Prize token image *</label>
          <input
            v-model="pool.tokenImage"
            aria-describedby="addon-right addon-left"
            placeholder="https://storage.googleapis.com/dev-blast.appspot.com/assets/Misc/tokens/matic.png"
            class="form-control"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    pool: {
      type: Object,
      default: () => {},
    },
    selectedPool: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      entranceTokens: [],
      tokens: [],
    };
  },
  methods: {
    selectEntranceToken(tokenName) {
      const token = this.tokens.find(token => token.name == tokenName);
      const { name, symbol, address, logoURI } = token || {};

      this.pool.enrtanceTokenName = name || '';
      this.pool.entranceTokenSymbol = symbol || '';
      this.pool.entranceTokenAddress = address || '';
      this.pool.entranceTokenImage = logoURI || '';
    },
    selectToken(tokenName) {
      const token = this.tokens.find(token => token.name == tokenName);
      const { name, symbol, address, logoURI } = token || {};

      this.pool.tokenName = name || '';
      this.pool.tokenSymbol = symbol || '';
      this.pool.tokenAddress = address || '';
      this.pool.tokenImage = logoURI || '';
    },
  },
  async mounted() {
    const goerliTokens = this.$store.getters['pools/goerliTokens'];
    const polygonTokens = this.$store.getters['pools/polygonTokens'];

    this.entranceTokens = this.env === 'dev' ? [{ name: '' }, ...goerliTokens] : [{ name: '' }, ...polygonTokens];
    this.tokens = this.env === 'dev' ? [...goerliTokens] : [...polygonTokens];

    this.selectEntranceToken(this.entranceTokens[0].name);
    this.selectToken(this.tokens[0].name);
  },
};
</script>
<style lang="" scoped></style>
