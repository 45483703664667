import { BaseInput, BaseCard, BaseDropdown, BaseButton, BaseCheckbox } from "../components";

const GlobalComponents = {
  install(Vue) {
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseCard.name, BaseCard);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
  }
};

export default GlobalComponents;
