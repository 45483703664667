<template>
  <div class="mt-4 mb-3 d-flex flex-column">
    <span v-if="loading">
      <LoadingComponent />
    </span>

    <div v-else>
      <div class="col-12 row">
        <div class="col-3 form-group">
          <datepicker v-model="from" placeholder="From" :bootstrap-styling="true" :disabled="false" />
        </div>
        <div class="col-3 form-group">
          <datepicker v-model="to" placeholder="To" :bootstrap-styling="true" />
        </div>
        <div class="col-3 form-group">
          <base-button
            simple
            type="primary"
            class="center"
            style="cursor: pointer width: 60px"
            @click="getOrganizationStats(true)"
            :disabled="loading"
          >
            <span class="center">filter</span>
          </base-button>
        </div>
      </div>

      <div v-if="hasStats" class="col-12 my-3 row justify-content-center">
        <div class="col-4">
          <div class="col-12 mr-3 p-3 card">
            <h3 class="row justify-content-center">Users registered</h3>
            <h1 class="row justify-content-center">{{ usersRegistered }}</h1>
          </div>
        </div>
        <div class="col-4">
          <div class="col-12 mx-3 p-3 card">
            <h3 class="row justify-content-center">Users joined</h3>
            <h1 class="row justify-content-center">{{ usersJoinedPool }}</h1>
          </div>
        </div>
        <div class="col-4">
          <div class="col-12 ml-3 p-3 card">
            <h3 class="row justify-content-center">Users played</h3>
            <h1 class="row justify-content-center">{{ usersPlayedMatch }}</h1>
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <base-table :data="usersTable.data" :columns="usersTable.columns" thead-classes="text-primary">
          <template slot-scope="{ row }">
            <td>{{ row.name }}</td>
            <td>{{ row.createdOn }}</td>
            <td>{{ row.permissions }}</td>
            <td class="td-actions text-right">
              <div class="mr-2" size="sm" style="cursor: pointer" @click="">...</div>
            </td>
          </template>
        </base-table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';

import { BaseTable } from '@/components';
import LoadingComponent from '@/components/LoaderSpinner';

const tableColumns = ['Name', 'Created', 'Permissions', 'Actions'];

export default {
  name: '',
  components: {
    BaseTable,
    Datepicker,
    LoadingComponent,
  },
  mixins: [],
  props: {
    selectedOrganization: {
      type: Object,
      default: () => {},
    },
    organizationStats: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: true,
      from: moment().subtract(1, 'months').valueOf(),
      to: moment().valueOf(),
      usersTable: {
        title: 'Users',
        columns: [...tableColumns],
        data: [],
      },
    };
  },
  computed: {
    hasStats() {
      return this.organizationStats && Object.keys(this.organizationStats).length > 0;
    },
    usersRegistered() {
      return this.organizationStats.usersRegistered?.length || 0;
    },
    usersJoinedPool() {
      return this.organizationStats.usersJoinedPool?.length || 0;
    },
    usersPlayedMatch() {
      return this.organizationStats.usersPlayedMatch?.length || 0;
    },
  },
  watch: {},
  mounted() {
    this.loading = true;

    const usersData = this.selectedOrganization.users.map(user => {
      const { id, createdOn, userNick, userPermissions } = user;
      const actions = '';
      const permissions = String(userPermissions.map(permission => ` ${permission}`));

      return {
        id,
        name: userNick,
        permissions: permissions,
        createdOn: moment(createdOn).format('YYYY-MM-DD HH:mm'),
        actions,
      };
    });

    this.usersTable.data = usersData;

    this.loading = false;
  },
  methods: {
    getOrganizationStats() {
      const startFrom = moment(this.from).startOf('day').valueOf();
      const endTo = moment(this.to).endOf('day').valueOf();

      this.$emit('getOrganizationStats', { from: startFrom, to: endTo });
    },
  },
};
</script>

<style>
.center {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.flex {
  display: flex;
  align-items: center;
}
.form-text {
  color: white;
  font-size: 14px !important;
}

.color {
  color: #222a42;
}

.btn {
  margin: 0px !important;
  padding: 0px 25px;
}
</style>
