<template>
  <div class="wrapper">
    <side-bar :background-color="backgroundColor">
      <template slot="links">
        <sidebar-link to="/dashboard" :name="$t('sidebar.dashboard')" icon="fa-chart-line" />
        <sidebar-link to="/organizations" :name="$t('sidebar.organizations')" icon="fa-sitemap" />
        <sidebar-link to="/teams" :name="$t('sidebar.teams')" icon="fa-user-group" />
        <sidebar-link to="/users" :name="$t('sidebar.users')" icon="fa-user" />
        <sidebar-link to="/accounts" :name="$t('sidebar.accounts')" icon="fa-file-invoice" />
        <sidebar-link to="/games" :name="$t('sidebar.games')" icon="fa-gamepad" />
        <sidebar-link to="/pools" :name="$t('sidebar.pools')" icon="fa-ranking-star" />
        <sidebar-link to="/advertisements" :name="$t('sidebar.advertisements')" icon="fa-rectangle-ad" />
        <sidebar-link to="/templates" :name="$t('sidebar.templates')" icon="fa-shapes" />
        <sidebar-link to="/translations" :name="$t('sidebar.translations')" icon="fa-language" />
        <sidebar-link to="/notifications" :name="$t('sidebar.notifications')" icon="fa-bell" />
      </template>
    </side-bar>
    <div class="main-panel" :data="backgroundColor">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss"></style>
<script>
import TopNavbar from './TopNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import DashboardContent from './Content.vue';

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    // MobileMenu,
    // SidebarShare
  },
  data() {
    return {
      backgroundColor: 'vue',
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
