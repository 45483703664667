<template>
  <div>
    <div class="mt-3 mb-3 d-flex flex-column">
      <div>
        <base-button
          :simple="!showPlayers"
          type="default"
          class="btn-round mb-3 mr-2"
          style="cursor: pointer"
          @click="showPlayersContent()"
        >
          {{ playersOption }}
        </base-button>
        <base-button
          :simple="!showInvitationalCodes"
          type="default"
          class="btn-round mb-3 mr-2"
          style="cursor: pointer"
          @click="showInvitationalCodesContent()"
          >invitational codes</base-button
        >
        <base-button
          :simple="!showConfig"
          type="default"
          class="btn-round mb-3 mr-2"
          style="cursor: pointer"
          @click="showConfigContent()"
          >config</base-button
        >
      </div>
      <div class="d-flex flex-row-reverse">
        <base-button
          :hidden="!showInvitationalCodes"
          simple
          type="primary"
          :disabled="loading"
          class="mb-3 mr-2"
          style="cursor: pointer; width: fit-content"
          @click="createInvitationalCode()"
        >
          <span v-if="loading">
            <LoadingComponent />
          </span>
          <p v-else style="margin: 0">Create code</p>
        </base-button>
      </div>
    </div>

    <span v-if="loading">
      <LoadingComponent />
    </span>

    <div v-if="!loading && showPlayers" :title="selectedPool?.titleSection2">
      <LeaderboardTable
        v-if="isLeaderboard"
        :selectedPool="selectedPool"
        @removePlayer="removePlayer"
        @changeResult="changeResult"
      />
      <BracketsTable v-if="!isLeaderboard" :selectedPool="selectedPool" />
    </div>

    <div v-if="!loading && showInvitationalCodes" :title="selectedPool?.titleSection">
      <div v-if="!hasInvitationalCodes">
        <p class="text-center p-3">There are no invitational codes for this pool</p>
      </div>
      <div v-else class="table-responsive">
        <base-table
          :data="selectedPool.invitationalCodes.data"
          :columns="selectedPool.invitationalCodes.columns"
          thead-classes="text-primary"
        >
          <template slot-scope="{ row }">
            <td>{{ row.code }}</td>
            <td>{{ row.active ? 'active' : 'inactive' }}</td>
          </template>
        </base-table>
      </div>
    </div>

    <div v-if="!loading && showConfig">
      <pool-form :selectedPool="this.selectedPool"></pool-form>
    </div>
  </div>
</template>

<script>
import { BaseTable } from '@/components';
import BracketsTable from '@/components/Pools/BracketsTable';
import LeaderboardTable from '@/components/Pools/LeaderboardTable';
import PoolForm from '@/components/Pools/Form/PoolForm';
import LoadingComponent from '@/components/LoaderSpinner';

export default {
  components: {
    BaseTable,
    BracketsTable,
    LeaderboardTable,
    PoolForm,
    LoadingComponent,
  },
  name: 'pool-description',
  props: {
    selectedPool: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      showPlayers: true,
      showLeaderboard: true,
      showInvitationalCodes: false,
      showConfig: false,
    };
  },
  computed: {
    hasInvitationalCodes() {
      return this.selectedPool && this.selectedPool.invitationalCodes?.data?.length;
    },
    isLeaderboard() {
      return (
        !this.selectedPool.poolMode ||
        this.selectedPool.poolMode === 'LEADERBOARD' ||
        this.selectedPool.poolMode === 'ROUND_ROBIN'
      );
    },
    playersOption() {
      return this.isLeaderboard ? 'leaderboard' : 'brackets';
    },
  },
  methods: {
    async createInvitationalCode() {
      this.loading = true;
      try {
        const poolData = {
          poolId: this.selectedPool.id,
        };
        const codeCreated = await this.$store.dispatch('pools/createInvitationalCode', poolData);

        if (codeCreated?.error) {
          await this.$notify({
            type: 'danger',
            message: `Error creating invitational code: ${codeCreated?.message}`,
          });

          this.loading = false;
          return;
        }

        await this.$notify({
          type: 'success',
          message: `Invitational code ${codeCreated?.message} created successfully!`,
        });

        this.loading = false;
        this.$emit('codeCreated', true);
      } catch (error) {
        console.log(error);

        await this.$notify({
          type: 'danger',
          message: `Error creating invitational code: ${error}`,
        });

        this.loading = false;
      }
    },
    showPlayersContent() {
      this.showPlayers = true;
      this.showInvitationalCodes = false;
      this.showConfig = false;
    },
    showInvitationalCodesContent() {
      this.showPlayers = false;
      this.showInvitationalCodes = true;
      this.showConfig = false;
    },
    showConfigContent() {
      this.showPlayers = false;
      this.showInvitationalCodes = false;
      this.showConfig = true;
    },
    async removePlayer(player) {
      this.loading = true;

      const { aTag, id: userId } = player;
      const poolId = this.$route.params.poolId;

      const data = { aTag, poolId, userId };
      const removePlayerResponse = await this.$store.dispatch('pools/removePlayer', data);
      const { error: removePlayerError, message: removePlayerMessage } = removePlayerResponse;

      if (removePlayerError) {
        await this.$notify({
          type: 'danger',
          message: `Error removing player: ${removePlayerMessage}`,
        });

        this.loading = false;
        return;
      }

      await this.$notify({ type: 'success', message: `Player removed: ${aTag}` });

      this.changeResult();

      this.loading = false;
    },
    async changeResult() {
      this.$emit('changeResult');
    },
  },
};
</script>

<style>
.center {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
</style>
