<template>
  <nav
    class="navbar navbar-expand-lg navbar-absolute"
    :class="{ 'bg-white': showMenu, 'navbar-transparent': !showMenu }"
  >
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <div class="navbar-toggle d-inline" :class="{ toggled: $sidebar.showSidebar }">
          <button type="button" class="navbar-toggler" aria-label="Navbar toggle button" @click="toggleSidebar">
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </button>
        </div>
        <a class="navbar-brand" href="#pablo">{{ routeName }}</a>
      </div>
      <button
        class="navbar-toggler"
        type="button"
        @click="toggleMenu"
        data-toggle="collapse"
        data-target="#navigation"
        aria-controls="navigation-index"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
      </button>

      <collapse-transition>
        <div class="collapse navbar-collapse show" v-show="showMenu">
          <ul class="navbar-nav ml-auto">
            <div>
              <input
                v-model="searchQuery"
                v-on:keyup.enter="searchByType"
                type="text"
                class="form-control"
                id="inlineFormInputGroup"
                placeholder="SEARCH"
              />
            </div>
            <base-dropdown tag="li" title-tag="a" class="nav-item">
              <a slot="title" href="#" class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="true">
                <div class="notification d-none d-lg-block d-xl-block"></div>
                <i class="tim-icons icon-bell-55"></i>
                <p class="d-lg-none">New Notifications</p>
              </a>
              <li class="nav-link">
                <a href="#" class="nav-item dropdown-item">New Notifications</a>
              </li>
            </base-dropdown>
            <base-dropdown tag="li" title-tag="a" class="nav-item" menu-classes="dropdown-navbar">
              <a slot="title" class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="true">
                <div class="photo avatar-image" :style="{ 'background-image': `url('${profileImage}')` }" />
                <b class="caret d-none d-lg-block d-xl-block"></b>
                <p class="d-lg-none">Log out</p>
              </a>
              <li class="nav-link">
                <a href="/examples/user-profile" class="nav-item dropdown-item">Profile</a>
              </li>
              <div class="dropdown-divider"></div>
              <li class="nav-link">
                <a href="#" @click="logout" class="nav-item dropdown-item">Log out</a>
              </li>
            </base-dropdown>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </nav>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';

export default {
  components: {
    CollapseTransition,
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    profileImage() {
      return this.avatar ? this.avatar : '/img/placeholder.jpg';
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      avatar: null,
    };
  },
  async created() {
    this.$store.watch(
      () => this.$store.getters['profile/me'],
      me => {
        this.title = me.name;
        this.avatar = me.profile_image;
      },
    );
    await this.$store.dispatch('profile/me');
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    async searchByType() {
      const path = this.$route.path;
      const type = path.split('/')[1];
      const data = { searchQuery: this.searchQuery };

      if (type === 'teams') {
        await this.$store.dispatch('teams/searchTeams', data);
      }

      if (type === 'users') {
        await this.$store.dispatch('users/searchUsers', data);
      }

      if (type === 'games') {
        console.log(`games not implemented yet.`);
      }

      if (type === 'accounts') {
        console.log(`accounts not implemented yet.`);
      }

      if (type === 'pools') {
        await this.$store.dispatch('pools/searchPools', data);
      }

      this.searchModalVisible = false;
    },
    async logout() {
      try {
        this.$store.dispatch('logout');
      } catch (error) {
        this.$notify({
          type: 'danger',
          message: 'Oops, something went wrong!',
        });
      }
    },
  },
};
</script>

<style></style>
