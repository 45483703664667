<template>
  <div class="col-12 row">
    <div class="col-12 row">
      <div class="col-3 d-flex justify-content-center">id</div>
      <div class="col-2 d-flex justify-content-center">score</div>
      <div class="col-2 d-flex justify-content-center">map</div>
      <div class="col-2 d-flex justify-content-center">timestamp</div>
      <div class="col-1 d-flex justify-content-center"></div>
      <div class="col-2 d-flex justify-content-center">options</div>

      <div v-for="(report, reportIndex) in player.gamesData" class="col-12 row">
        <div class="col-3 form-group">{{ report.id }}</div>

        <div class="col-2 form-group">
          <input
            v-model="report.score"
            aria-describedby="addon-right addon-left"
            type="number"
            placeholder="player score"
            class="form-control text-center"
          />
        </div>

        <div class="col-2 form-group">
          <input
            v-model="report.mapName"
            aria-describedby="addon-right addon-left"
            placeholder="map"
            class="form-control text-center"
          />
        </div>

        <div class="col-2 form-group">{{ report.timestamp }}</div>
        <div class="col-1 d-flex justify-content-center"></div>

        <div class="col-2 d-flex justify-content-end">
          <font-awesome-icon icon="fa-floppy-disk" class="pointer mt-3 mr-3" @click="updateReport(reportIndex - 1)" />
          <font-awesome-icon
            icon="fa-trash"
            class="pointer mt-3"
            @click="deleteReport(resultIndex - 1)"
            :disabled="loading"
          />
        </div>
      </div>
    </div>

    <div class="col-12 mt-5" style="border: solid; border-color: #344675; border-radius: 12px; border-width: thin">
      <h4 class="pt-3 pb-3">Add a new report</h4>

      <div class="col-3 form-group">
        <label class="control-label form-text">Match id</label>
        <input
          v-model="leaderReport.matchId"
          aria-describedby="addon-right addon-left"
          placeholder="match id"
          class="form-control"
        />
      </div>

      <div class="col-8 form-group">
        <label class="control-label form-text">Score *</label>
        <div class="col-12 row">
          <input
            v-model="leaderReport.score"
            aria-describedby="addon-right addon-left"
            placeholder="player score"
            class="form-control col-6 row"
          />
        </div>
      </div>

      <div class="col-3 form-group">
        <label class="control-label form-text">Map</label>
        <input
          v-model="leaderReport.mapName"
          aria-describedby="addon-right addon-left"
          placeholder="map"
          class="form-control"
        />
      </div>
    </div>

    <div class="col-12 mt-3 row justify-content-end">
      <base-button
        simple
        type="primary"
        class="my-3 center"
        style="cursor: pointer width: 60px; height: 50px;"
        @click="!loading && handleReport()"
        :disabled="loading"
      >
        <span v-if="loading" class="center" style="width: 100%; height: 100%">
          <LoadingComponent />
        </span>
        <p v-else class="center">Report</p>
      </base-button>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import LoadingComponent from '@/components/LoaderSpinner';

export default {
  components: {
    FontAwesomeIcon,
    LoadingComponent,
  },
  props: {
    player: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      leaderReport: {
        matchId: '',
        mapName: '',
      },
      reports: [],
      loading: false,
    };
  },
  watch: {},
  methods: {
    handleReport() {
      const reportData = { ...this.leaderReport };

      this.$emit('reportMatch', reportData);
    },
    deleteReport(reportIndex) {
      //   this.$emit('deleteReport', idToDelete);
    },
    updateReport(reportIndex) {
      //   this.$emit('updateReport', reportData);
    },
  },
  async mounted() {},
};
</script>

<style>
.color {
  color: #222a42;
}
</style>
