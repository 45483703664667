import store from "@/store";

export const storeHelper = {
  errorRequest(status, error) {
    console.log('error', error);
    const { data: errorData = {} } = error.response;
    const { message, error: errorMessage } = errorData;

    if (errorMessage === 'Error trying to validate google token') {
      return store.dispatch('logout');
    }

    if (status === 400) {
      const splitMessage = message?.split(" - ");
      return { error: true, message: splitMessage && splitMessage[1] ? splitMessage[1] : error };
    } 
    
    if (status === 500) {
      return { error: true, message: message || errorData };
    } 

    return { error: true, message: message || errorData };
  }
};