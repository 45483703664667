import axios from 'axios';

import { storeHelper } from "../helpers";

const url = process.env.VUE_APP_BLAST_API_BASE_URL;

const state = {
  users: [],
  userAccount: [],
};

const mutations = {
  SET_USERS: (state, users) => {
    state.users = users;
  },
  SET_USER_ACCOUNT: (state, userAccount) => {
    state.userAccount = userAccount;
  },
};

const getters = {
  users: state => state.users,
  userAccount: state => state.userAccount,
};

const actions = {
  async getUser({ commit }, data) {
    try {
      const { userId } = data;
      const config = {
        headers: { token: localStorage.getItem('auth_access_token') },
      };

      const userResponse = await axios.get(`${url}/admin/users/${userId}`, config);
      const { data: user } = userResponse;
      
      commit('SET_USERS', user);

      return user;
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async getUsers({ commit }, data) {
    try {
      const config = {
        headers: { token: localStorage.getItem('auth_access_token') },
      };

      const users = await axios.get(`${url}/admin/users`, config);
      const { data: usersData } = users;

      commit('SET_USERS', usersData);

      return true;
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async searchUsers({ commit }, data) {
    try {
      const { searchQuery } = data;
      const config = {
        headers: { token: localStorage.getItem('auth_access_token') },
      };

      const users = await axios.get(`${url}/admin/users/search/${searchQuery}`, config);
      const { data: usersData } = users;

      commit('SET_USERS', usersData);

      return true;
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async getUserAccount({ commit }, data) {
    try {
      const { userId } = data;
      const userToSearch = `?&userId=${userId}`;
      const config = {
        headers: { token: localStorage.getItem('auth_access_token') },
      };

      const users = await axios.get(`${url}/admin/account${userToSearch}`, config);
      const { data: usersData } = users;

      commit('SET_USER_ACCOUNT', usersData);

      return usersData;
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async deleteUserAccount({ commit }, data) {
    try {
      const { userId, id: accountId } = data;
      const config = {
        headers: { token: localStorage.getItem('auth_access_token') },
      };
      
      await axios.delete(`${url}/admin/account/${userId}/${accountId}`, config);
      
      return true;
    } catch(error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async updateNickname({ commit }, data) {
    try {
      const { nick, userId } = data;
      const body = { nick };
      const config = {
        headers: { token: localStorage.getItem('auth_access_token') }
      }

      const updateNickResponse = await axios.put(`${url}/admin/users/${userId}`, body, config);
      const { data: updateNickData } = updateNickResponse;

      return updateNickData;
    } catch(error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async getUserTeams({ commit }, data) {
    try {
      const { userId } = data;
      const config = {
        headers: { token: localStorage.getItem('auth_access_token') },
      };

      const userTeamsResponse = await axios.get(`${url}/admin/users/${userId}/teams`, config);
      const { data: userTeams } = userTeamsResponse;

      return userTeams;
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
};

const users = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};

export default users;
