<template>
  <div class="row">
    <div class="col-12">
      <BaseModal
        v-if="hasResults"
        :show.sync="hasResults"
        class="modal-detail"
        id="resultsModal"
        :centered="false"
        :show-close="true"
      >
        <template slot="header">
          <h3 class="modal-title">{{ title }}</h3>
        </template>

        <Report
          :player="player"
          :opponents="opponents"
          :reportTemplate="selectedPool.reportTemplate"
          @updateReport="updateReport"
          @deleteReport="deleteReport"
          @reportMatch="reportMatch"
        ></Report>
      </BaseModal>
    </div>
  </div>
</template>

<script>
import { BaseModal } from '@/components';
import Report from '@/components/Pools/Results/Reports/Report';

export default {
  components: {
    BaseModal,
    Report,
  },
  props: {
    player: {
      type: Object,
      default: () => {},
    },
    opponents: {
      type: Array,
      default: () => [],
    },
    selectedPool: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      title: `${this.player?.nick} Results`,
      hasResults: false,
    };
  },
  computed: {
    isRoundRobin() {
      return this.selectedPool.poolMode === 'ROUND_ROBIN';
    },
  },
  watch: {
    player: function (playerValue, oldValue) {
      this.title = `${playerValue.nick} results`;
      this.hasResults = playerValue && Object.keys(playerValue).length > 0;
    },
  },
  methods: {
    async reportMatch(reportData) {
      const data = {
        poolId: this.selectedPool.id,
        userId: this.player.id,
        report: {
          reportData,
        },
      };

      this.loading = true;
      const reportResponse = await this.$store.dispatch('pools/createReport', data);

      if (reportResponse.error) {
        await this.$notify({
          type: 'danger',
          message: `Error trying to report the match`,
        });

        this.loading = false;
        return;
      }

      await this.$notify({
        type: 'success',
        message: `Success creating new report: ${reportResponse?.message}`,
      });

      this.$emit('changeResult');
      this.loading = false;
    },
    async updateReport(reportData) {
      const data = {
        poolId: this.selectedPool.id,
        report: {
          playerId: this.player.referenceId,
          reportData,
        },
      };

      this.loading = true;
      const reportResponse = await this.$store.dispatch('pools/updateReport', data);

      if (reportResponse.error) {
        await this.$notify({
          type: 'danger',
          message: `Error trying to update a report`,
        });

        this.loading = false;
        return;
      }

      await this.$notify({
        type: 'success',
        message: `Success updating game report: ${reportResponse?.message.reportData.gameIndex + 1}`,
      });

      this.loading = false;
      this.$emit('changeResult');
    },
    async deleteReport(reportData) {
      const data = {
        poolId: this.selectedPool.id,
        report: {
          playerId: this.player.referenceId,
          reportId: reportData?.id || '',
        },
      };

      this.loading = true;
      const reportResponse = await this.$store.dispatch('pools/deleteReport', data);

      if (reportResponse.error) {
        await this.$notify({
          type: 'danger',
          message: `Error trying to Delete a report`,
        });

        this.loading = false;
        return;
      }

      await this.$notify({
        type: 'success',
        message: `Success deleting game report`,
      });

      this.loading = false;
      this.$emit('changeResult');
    },
  },
  async mounted() {
    this.loading = false;
  },
};
</script>
