<template>
  <div class="row">
    <div class="col-12">
      <BaseCard class="mt-3">
        <div class="col-12 form-group row">
          <img :src="user.thumbnail" class="rounded-circle m-3" width="80" height="80" alt="avatar" />
          <div class="col-2 d-flex align-items-center">
            <h1 id="exampleModalLabel">{{ user.nick }}</h1>
          </div>
        </div>

        <div class="col-12 form-group row">
          <label class="control-label form-text col-6">Nickname</label>
          <input
            v-model="user.nick"
            aria-describedby="addon-right addon-left"
            placeholder="new nickname"
            class="form-control form-text col-4 text-white"
          />

          <div class="col-1 d-flex align-items-center">
            <font-awesome-icon icon="fa-solid fa-floppy-disk" class="pointer" @click="updateNick()" />
          </div>
        </div>

        <div class="col-12 form-group row">
          <div class="col-6">ID</div>
          <div class="col-6">{{ user.id }}</div>
        </div>

        <div class="col-12 form-group row">
          <div class="col-6">Address</div>
          <div class="col-6">{{ user.address ? 'user.address' : '-' }}</div>
        </div>

        <div class="col-12 form-group row">
          <div class="col-6">Created</div>
          <div class="col-6">{{ !user.createdOn ? '-' : parseTime(user.createdOn) }}</div>
        </div>

        <div class="col-12 form-group row">
          <div class="col-6">Last login</div>
          <div class="col-6">{{ !user.lastLoginOn ? '-' : parseTime(user.lastLoginOn) }}</div>
        </div>

        <div class="col-12 form-group row">
          <div class="col-6">IP</div>
          <div class="col-6">{{ user.ipAddress ? user.ipAddress : '-' }}</div>
        </div>
      </BaseCard>

      <BaseCard class="mt-3">
        <div class="col-12 form-group row">
          <h2 class="col-12">Accounts</h2>

          <div v-if="!user.accounts?.length" class="col-12 my-3 text-center">This user doesn't have any account.</div>

          <div v-if="user.accounts?.length" class="col-12 row">
            <div class="col-5 flex center">Game</div>
            <div class="col-5 flex center">Username</div>
            <div class="col-2 flex center">Actions</div>
          </div>

          <div v-if="user.accounts?.length" class="col-12 my-3">
            <div v-for="(account, index) in user.accounts" :key="index" class="account-container">
              <div class="col-5">
                <label class="control-label form-text">{{ account.name }}</label>
              </div>

              <div class="col-5">
                <input
                  v-model="account.username"
                  aria-describedby="addon-right addon-left"
                  placeholder="new nickname"
                  class="form-control form-text"
                />
              </div>

              <div class="col-2">
                <font-awesome-icon
                  icon="fa-solid fa-floppy-disk"
                  class="mx-2 pointer"
                  @click="updateAccount(account)"
                />
                <font-awesome-icon icon="fa-solid fa-trash" class="pointer" @click="deleteAccount(account)" />
              </div>
            </div>
          </div>
        </div>
      </BaseCard>

      <BaseCard class="mt-3">
        <div class="col-12 form-group row">
          <h2 class="col-12">Teams</h2>

          <div v-if="!teams.length" class="col-12 my-3 text-center">This user doesn't belongs to any team.</div>

          <div v-if="teams.length" class="col-12 row">
            <div class="col-4 center">ID</div>
            <div class="col-3 center">Name</div>
            <div class="col-3 center">Created</div>
            <div class="col-2 center">Actions</div>
          </div>

          <div v-if="teams.length" class="col-12 my-3">
            <div v-for="(team, index) in teams" :key="index" class="account-container">
              <div class="col-4">
                <label class="control-label form-text">{{ team.id }}</label>
              </div>

              <div class="col-3">
                <label class="control-label form-text">{{ team.name }}</label>
              </div>

              <div class="col-3">
                <label class="control-label form-text">{{ parseTime(team.createdOn) }}</label>
              </div>

              <div class="col-2">
                <!-- <font-awesome-icon icon="fa-solid fa-trash" class="pointer" /> -->
              </div>
            </div>
          </div>
        </div>
      </BaseCard>

      <BaseCard class="mt-3">
        <div class="col-12 form-group row">
          <div class="col-6">Pools</div>
          <div class="col-6">{{ user.pools }}</div>
        </div>
      </BaseCard>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { BaseTable, BaseModal, BaseCard } from '@/components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import LoadingComponent from '@/components/LoaderSpinner';

export default {
  name: '',
  components: {
    FontAwesomeIcon,
    LoadingComponent,
    BaseCard,
  },
  mixins: [],
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    teams: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    updateNick() {
      this.$emit('updateNick');
    },
    updateAccount(account) {
      this.$emit('updateAccount', account);
    },
    deleteAccount(account) {
      const { userId, id } = account;
      const data = { userId, id };

      this.$emit('deleteAccount', data);
    },
    parseTime(time) {
      return moment(time).format('MMM Do YYYY, h:mm:ss a');
    },
  },
};
</script>
<style scoped>
.account-container {
  display: flex;
  gap: 10px;
  padding: 10px 10px;
  justify-content: space-between;
}
</style>
