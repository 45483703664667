import axios from "axios";

import { storeHelper } from "../helpers";

const url = process.env.VUE_APP_BLAST_API_BASE_URL;

const state = {
  monitors: []
};

const mutations = {
  SET_MONITORS: (state, monitors) => {
    state.monitors = monitors;
  },
}

const getters = {
  monitors: state => state.monitors
};

const actions = {
  async getMonitors({ commit }, data) {
    try {
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
  
      const monitors = await axios.get(`${url}/admin/monitor`, config);
      const { data: monitorsData } = monitors;
  
      commit('SET_MONITORS', monitorsData);
  
      return true;
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
};

const monitors = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};

export default monitors;