<template>
  <div class="card-body">
    <form class="col-12 row">
      <div class="col-12 no-padding">
        <div class="dropbox lg">
          <input
            type="file"
            id="file"
            name="file"
            :disabled="loading"
            @change="updatefile($event.target.files, 'large')"
            accept="image/*"
            class="input-file"
          />
          <img v-if="isSuccessLarge" :src="ad.largeImage" alt="large image" />
          <p v-if="isInitialLarge">
            Drag your large ad image here<br />
            or click to browse
          </p>
          <p v-if="isSavingLarge">Uploading files...</p>
        </div>
      </div>

      <div class="col-12 row mt-3">
        <div class="col-6 row">
          <div class="col-12 form-group">
            <label class="control-label">Title *</label>
            <small v-if="!titleValid" class="col-12 form-text" style="color: #f66555">title can't be empty.</small>
            <input
              v-model="ad.title"
              aria-describedby="addon-right addon-left"
              placeholder="ad title"
              class="form-control"
            />
          </div>

          <div class="col-12 form-group">
            <label class="control-label">Description *</label>
            <small v-if="!descriptionValid" class="col-12 form-text" style="color: #f66555">
              description can't be empty.
            </small>
            <input
              v-model="ad.description"
              aria-describedby="addon-right addon-left"
              placeholder="description"
              class="form-control"
            />
          </div>

          <div class="col-12 form-group">
            <label class="control-label">Url</label>
            <input v-model="ad.url" aria-describedby="addon-right addon-left" placeholder="url" class="form-control" />
          </div>

          <div class="col-6 form-group">
            <label class="control-label">Start date *</label>
            <datepicker v-model="ad.startDate" placeholder="ad start date" :bootstrap-styling="true" />
          </div>

          <div class="col-6 form-group">
            <label class="control-label">End date *</label>
            <datepicker v-model="ad.endDate" placeholder="ad end date" :bootstrap-styling="true" />
          </div>

          <div class="col-12 mb-2 ml-3 form-check">
            <label for="active" class="form-check-label">
              <input v-model="ad.active" id="active" type="checkbox" class="form-check-input" />
              <span class="form-check-sign"></span>
              Active
            </label>
          </div>

          <div class="col-12 form-group">
            <label class="control-label">Type</label>
            <select v-model="ad.type" class="form-control">
              <option class="color" v-for="(item, index) in this.adTypes" :value="item" :key="index">
                {{ item.name }}
              </option>
            </select>
          </div>

          <div class="col-12 row">
            <label class="col-10 control-label">Values</label>
            <label class="col-1 control-label" style="cursor: pointer" @click="addValue()"> + </label>
            <label class="col-1 control-label" style="cursor: pointer" @click="removeValue()"> - </label>
          </div>

          <div v-for="(item, index) in this.ad.values" :key="index" class="col-12 row">
            <div class="col-12 row form-group">
              <input
                v-model="ad.values[index]"
                type="number"
                @keyup.enter="addValue()"
                aria-describedby="addon-right addon-left"
                placeholder="value"
                class="col-10 form-control"
              />
            </div>
          </div>
        </div>

        <div class="col-6 row justify-content-center">
          <div class="dropbox sm">
            <input
              type="file"
              id="file"
              name="file"
              :disabled="loading"
              @change="updatefile($event.target.files, 'small')"
              accept="image/*"
              class="input-file"
            />
            <img v-if="isSuccessSmall" :src="ad.smallImage" alt="small image" />
            <p v-if="isInitialSmall">
              Drag your small ad image here<br />
              or click to browse
            </p>
            <p v-if="isSavingSmall">Uploading files...</p>
          </div>
        </div>
      </div>

      <div class="col-12 mt-3 row justify-content-end">
        <base-button
          v-if="!hasSelectedAd"
          simple
          type="primary"
          class="my-3 center"
          style="cursor: pointer width: 60px; height: 50px;"
          @click="createAd()"
          :disabled="loading"
        >
          <span v-if="loading" class="center" style="width: 100%; height: 100%">
            <LoadingComponent />
          </span>
          <p v-else class="center">Create</p>
        </base-button>
        <base-button
          v-else
          simple
          type="primary"
          class="my-3 center"
          style="cursor: pointer width: 60px; height: 50px;"
          @click="updateAd()"
          :disabled="loading"
        >
          <span v-if="loading" class="center">
            <LoadingComponent />
          </span>
          <p v-else class="center">Update</p>
        </base-button>
      </div>
    </form>
  </div>
</template>

<script>
import * as moment from 'moment';
import Datepicker from 'vuejs-datepicker';

import { BaseModal } from '@/components';
import LoadingComponent from '../LoaderSpinner.vue';

const STATUS_INITIAL = 0;
const STATUS_SAVING = 1;
const STATUS_SUCCESS = 2;
const STATUS_FAILED = 3;

export default {
  components: {
    BaseModal,
    Datepicker,
    LoadingComponent,
  },
  props: {
    selectedAd: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ad: {},
      titleValid: true,
      descriptionValid: true,
      loading: false,
      disabledButton: false,
      adTypes: [{ name: 'DASHBOARD' }, { name: 'GAME' }],
      smallFileStatus: STATUS_INITIAL,
      largeFileStatus: STATUS_INITIAL,
    };
  },
  computed: {
    isSelectedAd() {
      return Object.keys(this.selectedAd).length > 0;
    },
    isInitialSmall() {
      return this.smallFileStatus === STATUS_INITIAL;
    },
    isSavingSmall() {
      return this.smallFileStatus === STATUS_SAVING;
    },
    isSuccessSmall() {
      return this.smallFileStatus === STATUS_SUCCESS;
    },
    isFailedSmall() {
      return this.smallFileStatus === STATUS_FAILED;
    },
    isInitialLarge() {
      return this.largeFileStatus === STATUS_INITIAL;
    },
    isSavingLarge() {
      return this.largeFileStatus === STATUS_SAVING;
    },
    isSuccessLarge() {
      return this.largeFileStatus === STATUS_SUCCESS;
    },
    isFailedLarge() {
      return this.largeFileStatus === STATUS_FAILED;
    },
    hasSelectedAd() {
      return this.selectedAd && Object.keys(this.selectedAd).length;
    },
  },
  methods: {
    addValue() {
      this.ad.values.push('');
    },
    removeValue() {
      this.ad.values.pop();
    },
    validateFields(ad) {
      const { title, description, startDate, endDate } = ad;
      this.titleValid = title?.length;
      this.descriptionValid = description?.length;

      return this.titleValid && this.descriptionValid;
    },
    async updatefile(fileList, type) {
      if (!fileList.length) {
        return;
      }

      const file = fileList[0];
      const fileName = file.name.split('.')[0].replace(/ /g, '-');
      const fullName = `${fileName}-${type}`;
      const formData = new FormData();

      this.loading = true;
      formData.append(fullName, file);
      formData.append('type', 'advertisement');

      this[`${type}FileStatus`] = STATUS_SAVING;
      const updateFileResponse = await this.$store.dispatch('advertisements/updateAdvertisementImage', formData);
      console.log('updateFileResponse', updateFileResponse);
      if (updateFileResponse?.error) {
        await this.$notify({
          type: 'danger',
          message: `Error updating ad image: ${updateFileResponse?.message}`,
        });

        this[`${type}FileStatus`] = STATUS_FAILED;
        this.loading = false;
        return;
      }

      await this.$notify({
        type: 'success',
        message: `Ad ${type} image uploaded successfully!`,
      });

      this.ad[`${type}Image`] = updateFileResponse.url;
      this[`${type}FileStatus`] = STATUS_SUCCESS;
      this.loading = false;
    },
    async createAd() {
      try {
        const validFields = this.validateFields(this.ad);

        if (!validFields) {
          return;
        }

        const { active = false, endDate, startDate, type, url, values = [], smallImage = '' } = this.ad;
        const ad = {
          ...this.ad,
          active,
          endDate: moment(endDate, moment.ISO_8601).valueOf(),
          startDate: moment(startDate, moment.ISO_8601).valueOf(),
          smallImage,
          type: type.name,
          url,
          values,
        };

        this.loading = true;
        const adResponse = await this.$store.dispatch('advertisements/createAdvertisement', ad);

        if (adResponse?.error) {
          await this.$notify({
            type: 'danger',
            message: `Error updating ad: ${adResponse?.message}`,
          });

          this.loading = false;
          return;
        }

        await this.$notify({
          type: 'success',
          message: `Ad ${adResponse.title} created successfully!`,
        });

        this.ad = { ...this.$store.getters['advertisements/ad'] };
        this.smallFileStatus = STATUS_INITIAL;
        this.largeFileStatus = STATUS_INITIAL;
        this.loading = false;
      } catch (error) {
        await this.$notify({
          type: 'danger',
          message: `Error creating ad: ${error}`,
        });

        this.loading = false;
        return;
      }
    },
    async updateAd() {
      try {
        const validFields = this.validateFields(this.ad);

        if (!validFields) {
          return;
        }

        const { endDate, startDate, type, values = [] } = this.ad;
        const ad = {
          ...this.ad,
          endDate: moment(endDate, moment.ISO_8601).valueOf(),
          startDate: moment(startDate, moment.ISO_8601).valueOf(),
          type: type.name,
          values,
        };

        this.loading = true;
        const adResponse = await this.$store.dispatch('advertisements/updateAd', ad);

        if (adResponse?.error) {
          await this.$notify({
            type: 'danger',
            message: `Error updating ad: ${adResponse?.message}`,
          });

          this.loading = false;
          return;
        }

        await this.$notify({
          type: 'success',
          message: `Ad ${adResponse?.message} updated successfully!`,
        });

        this.loading = false;
      } catch (error) {
        await this.$notify({
          type: 'danger',
          message: `Error updating ad: ${error}`,
        });

        this.loading = false;
        return;
      }
    },
  },
  async mounted() {
    // update ad
    if (this.isSelectedAd) {
      this.ad = {
        ...this.selectedAd,
        type: { name: this.selectedAd.type },
      };

      this.smallFileStatus = STATUS_SUCCESS;
      this.largeFileStatus = STATUS_SUCCESS;

      return;
    }

    // new ad
    this.ad = { ...this.$store.getters['advertisements/ad'] };
  },
};
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.no-padding {
  padding: 0px !important;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.flex {
  display: flex;
  align-items: center;
}

.dropbox {
  outline: 1px dashed #f2f2f2;
  background: rgb(32, 19, 102);
  color: #32325d;
  position: relative;
  cursor: pointer;
}

.lg {
  min-height: 200px;
}

.sm {
  max-height: 240px;
  width: 315px;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: #221c4a;
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}

img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 0;
}

.modal-detail .modal-dialog span {
  font-size: 17px !important;
  color: #9a9a9a !important;
}

.vdp-datepicker__calendar .cell.selected {
  background: rgb(32, 19, 102) !important;
}
</style>
