import axios from "axios";

import { storeHelper } from "../helpers";

const url = process.env.VUE_APP_BLAST_API_BASE_URL;

const state = {
  organization: {},
  organizationStats: {},
  organizations: [],
};

const mutations = {
  SET_ORGANIZATION: (state, organization) => {
    state.organization = organization;
  },
  SET_ORGANIZATION_STATS: (state, stats) => {
    state.organizationStats = stats;
  },
  SET_ORGANIZATIONS: (state, organizations) => {
    state.organizations = organizations;
  },
}

const getters = {
  organizations: state => state.organizations,
};

const actions = {
  async createOrganization({ commit }, data) {
    try {
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
  
      const organizationResponse = await axios.post(`${url}/admin/organization`, data, config);
      const { data: organizationData } = organizationResponse;
      const { id: organizationId } = organizationData;
  
      return { error: false, message: organizationId }; 
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async getOrganizations({ commit }, data) {
    try {
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
      
      const organization = await axios.get(`${url}/admin/organization`, config);
      const { data: organizationsData } = organization;
  
      commit('SET_ORGANIZATIONS', organizationsData);
  
      return organizationsData;
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async getOrganization({ commit }, data) {
    try {
      const { organizationId } = data;
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
  
      const organization = await axios.get(`${url}/admin/organization/${organizationId}`, config);
      const { data: organizationData } = organization;

      commit('SET_ORGANIZATION', organizationData);
  
      return organizationData;
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async getOrganizationStats({ commit }, data) {
    try {
      const { organizationId } = data;
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
  
      const organization = await axios.post(`${url}/admin/organization/${organizationId}/stats`, data, config);
      const { data: organizationData } = organization;

      commit('SET_ORGANIZATION_STATS', organizationData);
  
      return organizationData;
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async updateOrganization({ commit }, data) {
    try {
      const { id: organizationId } = data;
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
  
      const organizationResponse = await axios.put(`${url}/admin/organization/${organizationId}`, data, config);
      const { data: organizationData } = organizationResponse;
  
      return { error: false, message: organizationId }; 
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
};

const organizations = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};

export default organizations;
