<template>
  <div class="col-12 row">
    <div class="col-12 row">
      <div class="col-3 d-flex justify-content-center">
        {{ reportData?.match.p1Nickname }}
      </div>

      <div class="col-3 d-flex justify-content-center">
        {{ reportData?.match.p2Nickname }}
      </div>

      <div class="col-2 d-flex justify-content-center">Map</div>
      <div class="col-2 d-flex justify-content-center">Status</div>
      <div class="col-2 d-flex justify-content-center">Options</div>
    </div>

    <div v-for="resultIndex in reportData.match.p1Results.length" class="col-12 row mb-2">
      <div class="col-3 px-5 d-flex justify-content-center">
        <input
          v-model="reportData.match.p1Results[resultIndex - 1].details.score"
          type="number"
          aria-describedby="addon-right addon-left"
          class="form-control px-5 d-flex justify-content-center"
        />
      </div>

      <div class="col-3 px-5 d-flex justify-content-center">
        <input
          v-model="reportData.match.p2Results[resultIndex - 1].details.score"
          type="number"
          aria-describedby="addon-right addon-left"
          class="form-control px-5 d-flex justify-content-center"
        />
      </div>

      <div class="col-2 d-flex justify-content-center">
        <input
          v-model="reportData.match.p1Results[resultIndex - 1].details.map"
          aria-describedby="addon-right addon-left"
          class="form-control"
        />
      </div>

      <div class="col-2 d-flex justify-content-center">
        <select v-model="reportData.match.p1Results[resultIndex - 1].status" class="form-control">
          <option class="color" v-for="(item, index) in matchStatus" :key="index">
            {{ item }}
          </option>
        </select>
      </div>

      <div class="col-2 d-flex justify-content-center">
        <font-awesome-icon icon="fa-floppy-disk" class="pointer mt-3 mr-3" @click="updateReport(resultIndex - 1)" />
        <font-awesome-icon icon="fa-trash" class="pointer mt-3" @click="deleteReport(resultIndex - 1)" :disabled="loading" />
      </div>
    </div>

    <div class="col-12 mt-5" style="border: solid; border-color: #344675; border-radius: 12px; border-width: thin">
      <h4 class="pt-3 pb-3">Add a new report</h4>
      <div class="col-6 form-group">
        <label class="control-label form-text">Winner</label>
        <select v-model="scoreReport.winner" class="form-control">
          <option class="color" v-for="(item, index) in this.players" :value="item" :key="index">
            {{ item.nick }}
          </option>
        </select>
      </div>

      <div class="col-6 form-group">
        <label class="control-label form-text">Match id</label>
        <input
          v-model="scoreReport.matchId"
          aria-describedby="addon-right addon-left"
          placeholder="Match ID"
          class="form-control"
        />
      </div>

      <div class="col-6 form-group">
        <label class="control-label form-text">Replay URL</label>
        <input
          v-model="scoreReport.replayURL"
          aria-describedby="addon-right addon-left"
          placeholder="replay url"
          class="form-control"
        />
      </div>

      <div class="col-8 form-group">
        <label class="control-label form-text">Score *</label>
        <div class="col-12 row">
          <input
            v-model="scoreReport.matchDetails.score[0]"
            aria-describedby="addon-right addon-left"
            placeholder="first score"
            class="form-control col-6 row"
          />
          <input
            v-model="scoreReport.matchDetails.score[1]"
            type="number"
            aria-describedby="addon-right addon-left"
            placeholder="second score"
            class="form-control col-6 row"
          />
        </div>
      </div>

      <div class="col-4 form-group">
        <label class="control-label form-text">Map</label>
        <input
          v-model="scoreReport.matchDetails.map"
          aria-describedby="addon-right addon-left"
          placeholder="round map"
          class="form-control"
        />
      </div>
    </div>

    <div class="col-12 mt-3 row justify-content-end">
      <base-button
        simple
        type="primary"
        class="my-3 center"
        style="cursor: pointer width: 60px; height: 50px;"
        @click="!loading && createReport()"
        :disabled="loading"
      >
        <span v-if="loading" class="center" style="width: 100%; height: 100%">
          <LoadingComponent />
        </span>
        <p v-else class="center">Report</p>
      </base-button>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import LoadingComponent from '@/components/LoaderSpinner';

export default {
  components: {
    FontAwesomeIcon,
    LoadingComponent,
  },
  props: {
    reportData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      matchStatus: ['running', 'finished'],
      scoreReport: {
        matchId: '',
        replayUrl: '',
        matchDetails: {
          map: '',
          score: [],
        },
        status: 'running',
      },
      players: [],
    };
  },
  watch: {
    'scoreReport.matchDetails.score'(value) {
      this.scoreReport.winner = Number(value[0]) >= Number(value[1]) ? this.players[0] : this.players[1];
    },
  },
  methods: {
    createReport() {
      const reportData = { ...this.scoreReport };
      this.$emit('reportMatch', reportData);
    },

    deleteReport(gameIndex) {
      const match = this.reportData.match;
      const { p1Results } = match;
      const p1Result = p1Results[gameIndex];
      const idToDelete = p1Result.matchId;
      this.$emit('deleteReport', idToDelete);
    },

    updateReport(gameIndex) {
      const match = this.reportData.match;
      const { p1Results, p2Results } = match;
      const p1Result = p1Results[gameIndex];
      const p2Result = p2Results[gameIndex];

      const reportData = {
        gameIndex,
        matchDetails: {
          map: p1Result.details.map,
          score: [p1Result.details.score, p2Result.details.score],
        },
        status: p1Result.status,
        winner: Number(p1Result.details.score) >= Number(p2Result.details.score) ? this.players[0] : this.players[1],
      };

      this.$emit('updateReport', reportData);
    },
  },
  async mounted() {
    const { match } = this.reportData;
    const { p1aTag, p1Nickname, p2aTag, p2Nickname } = match;

    this.players = [
      { tag: p1aTag, nick: p1Nickname, winnerPosition: 1 },
      { tag: p2aTag, nick: p2Nickname, winnerPosition: 2 },
    ];
    this.scoreReport.winner = this.players[0];
  },
};
</script>

<style>
.color {
  color: #222a42;
}
</style>
