import axios from "axios";

import { storeHelper } from "../helpers";


const url = process.env.VUE_APP_BLAST_API_BASE_URL;

const state = {
  component: [],
  template: {},
  templates: []
};

const mutations = {
  SET_TEMPLATE: (state, template) => {
    state.template = template;
  },
  SET_TEMPLATES: (state, templates) => {
    state.templates = templates;
  },
}

const getters = {
  component: state => state.components,
  templates: state => state.templates,
  types: () => [
    { id: 'MAIN', name: 'Main' },
    { id: 'DETAILS', name: 'Details' },
    { id: 'REPORT', name: 'Report' },
  ],
  reportComponents: () => [
    'full-input',
    'select',
  ],
  detailComponents: () => [
    'h1',
    'h2',
    'hr',
    'full-input',
    'input',
    'label',
    'match-details',
    'match-results',
    'preview',
    'row',
    'table',
    'icon',
    'link',
  ],
  componentsRow: () => [
    'h1',
    'h2',
    'hr',
    'full-input',
    'input',
    'label',
    'icon',
    'link',
  ],
  componentsHasValue: () => [
    'h1',
    'h2',
    'full-input',
    'input',
    'label',
    'preview',
    'row',
    'table',
    'select'
  ],
  componentsHasStyle: () => [
    'h1',
    'h2',
    'full-input',
    'input',
    'label',
    'match-details',
    'match-results',
    'row',
  ],
  componentsHasType: () => [
    'input',
  ],
  componentsHasPlaceholder: () => [
    'input',
  ],
  componentsTypes: () => [
    'text',
  ],
  poolModes: () => [
    'LEADERBOARD',
    'ROUND_ROBIN',
    'SINGLE_ELIMINATION',
    'BEST_OF_3',
    'BEST_OF_5'
  ],
};

const actions = {
  async createTemplate({ commit }, data) {
    try {
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
  
      const templateResponse = await axios.post(`${url}/admin/template`, data, config);
      const { data: template } = templateResponse;
      const { id, name } = template;
      
      return { error: false, message: { id, name }}; 
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async getTemplates({ commit }, data) {
    try {
      const { game, poolMode } = data;
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
      let path = `/admin/template?`;

      path = game ? `${path}game=${game}&` : path;
      path = poolMode ? `${path}poolMode=${poolMode}&` : path;
  
      const templatesResponse = await axios.get(`${url}${path}`, config);
      const { data: templates } = templatesResponse;

      commit('SET_TEMPLATES', templates);
  
      return templates; 
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async getTemplate({ commit }, data) {
    try {
      const { templateId } = data;
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
  
      const templateResponse = await axios.get(`${url}/admin/template/${templateId}`, config);
      const { data: template } = templateResponse;

      commit('SET_TEMPLATE', template);
  
      return template;
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async updateTemplate({ commit }, data) {
    try {
      const { templateId, template } = data;
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
  
      const templateResponse = await axios.put(`${url}/admin/template/${templateId}`, template, config);
      
      return { error: false, message: template.name}; 
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
};

const templates = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};

export default templates;