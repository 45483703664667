<template>
  <div>
    <form>
      <div class="col-12 row">
        <div class="col-5 row form-group component-section">
          <label class="col-4 control-label form-text">Column name</label>
          <input
            v-model="component.key"
            aria-describedby="addon-right addon-left"
            placeholder="Name"
            class="form-control"
          />
        </div>

        <div class="col-5 row form-group component-section">
          <label class="col-4 control-label form-text">Property key</label>
          <input
            v-model="component.value"
            aria-describedby="addon-right addon-left"
            placeholder="Key"
            class="form-control"
          />
        </div>

        <div class="col-12 form-check">
          <label @click="component.visible = !component.visible" for="visible" class="form-check-label">
            <input v-model="component.visible" type="checkbox" class="form-check-input" />
            <span class="form-check-sign"></span>
            <span class="form-text">Visible</span>
          </label>
        </div>
      </div>

      <div class="col-12 mt-3 row justify-content-end">
        <base-button
          simple
          type="primary"
          class="my-3 center"
          style="cursor: pointer width: 60px; height: 50px;"
          @click="createComponent()"
        >
          <p class="center">Create</p>
        </base-button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      component: {
        key: null,
        value: null,
        visible: false,
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    createComponent() {
      const newComponent = structuredClone(this.component);

      this.$emit('createComponent', newComponent);
    },
  },
};
</script>

<style lang="" scoped></style>
