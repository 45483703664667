<template>
  <div class="card-body">
    <form>
      <Wizard :step="formStep"></Wizard>

      <General
        v-if="formStep === 1"
        :pool="pool"
        :nameValid="nameValid"
        :descriptionValid="descriptionValid"
        :selectedPool="selectedPool"
        @updateTemplates="updateTemplates"
        @enableJoinReqData="changeEnableJoinReqData"
        @enableUserProfileValidation="changeEnableUserProfileValidation"
      ></General>

      <Tokens v-if="formStep === 2" :pool="pool" :selectedPool="selectedPool"></Tokens>

      <Details
        v-if="formStep === 3"
        :pool="pool"
        :selectedPool="selectedPool"
        :detailsTemplateValid="detailsTemplateValid"
        :templates="templates"
        :winnersFeesValid="winnersFeesValid"
      ></Details>

      <Rules v-if="formStep === 4" :pool="pool"></Rules>

      <Manager
        :step="formStep"
        :selectedPool="selectedPool"
        :loading="loading"
        @savePool="savePool"
        @prevStep="prevStep"
        @nextStep="nextStep"
      ></Manager>
    </form>
  </div>
</template>

<script>
import * as moment from 'moment';

import General from '@/components/Pools/Form/General';
import Tokens from '@/components/Pools/Form/Tokens';
import Details from '@/components/Pools/Form/Details';
import Rules from '@/components/Pools/Form/Rules';
import Wizard from '@/components/Pools/Form/Wizard';
import Manager from '@/components/Pools/Form/Manager';

export default {
  components: {
    General,
    Tokens,
    Details,
    Rules,
    Wizard,
    Manager,
  },
  props: {
    selectedPool: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      env: process.env.VUE_APP_ENV,
      loading: false,
      enableJoinReqData: false,
      enableUserProfileValidation: false,
      nameValid: true,
      descriptionValid: true,
      entranceFeeValid: true,
      poolPrizeValid: true,
      minPlayersValid: true,
      maxPlayersValid: true,
      winnersFeesValid: true,
      detailsTemplateValid: true,
      entranceTokens: [],
      formStep: 1,
      tokens: [],
      templates: [],
      joinReqData: {},
      userProfileValidation: {},
      gameDetails: [],
      pool: {},
    };
  },
  methods: {
    changeEnableJoinReqData(enableJoinReqData) {
      this.enableJoinReqData = enableJoinReqData;
    },
    changeEnableUserProfileValidation(enableUserProfileValidation) {
      this.enableUserProfileValidation = enableUserProfileValidation;
    },
    validateGeneralFields() {
      const { description, entrance, maxPlayers, minPlayers, name, poolPrize } = this.pool;

      this.nameValid = !!name.length;
      this.descriptionValid = !!description.length;
      this.entranceFeeValid = entrance >= 0;
      this.poolPrizeValid = poolPrize >= 0;
      this.minPlayersValid = minPlayers >= 0;
      this.maxPlayersValid = maxPlayers >= 0;

      return (
        this.nameValid &&
        this.descriptionValid &&
        this.entranceFeeValid &&
        this.poolPrizeValid &&
        this.minPlayersValid &&
        this.maxPlayersValid
      );
    },
    validateDetailsFields() {
      const { detailsTemplateId, winnersFees = [] } = this.pool;

      this.detailsTemplateValid = !!detailsTemplateId;
      this.winnersFeesValid = winnersFees?.reduce((prev, current) => prev + Number(current), 0) == 100;

      return this.detailsTemplateValid && this.winnersFeesValid;
    },
    prevStep() {
      this.formStep -= 1;
    },
    nextStep() {
      const validGeneralFields = this.formStep === 1 ? this.validateGeneralFields() : true;
      const validDetailsFields = this.formStep === 3 ? this.validateDetailsFields() : true;

      if (validGeneralFields && validDetailsFields) {
        this.formStep += 1;
      }
    },
    mapGameDetails(gameDetails) {
      const { teamUsersQty } = gameDetails;
      const gameDetailsFields = gameDetails.fields?.map(field => ({ [field.key]: field.value }));
      let gameDetailsObject = {};

      if (teamUsersQty) {
        gameDetailsObject.teamUsersQty = Number(teamUsersQty);
      }

      for (let i = 0; i < gameDetailsFields?.length || 0; i++) {
        gameDetailsObject = { ...gameDetailsObject, ...gameDetailsFields[i] };
      }

      const { roundDurationType, gralDuration } = gameDetailsObject;

      if (gameDetailsObject.modeName) {
        gameDetailsObject.modeName = String(gameDetailsObject.modeName).split(',');
      }

      if (roundDurationType) {
        gameDetailsObject.specRoundData = { roundDurationType, gralDuration };

        delete gameDetailsObject.roundDurationType;
        delete gameDetailsObject.gralDuration;
      }

      delete gameDetailsObject.poolModes;
      delete gameDetailsObject.joinModes;

      return gameDetailsObject;
    },
    mapGameDetailsForm(selectedPoolGameDetails) {
      const gameDetailsOptions = [...this.$store.getters['pools/gameDetails']];
      const gameDetailsFormat = gameDetailsOptions.find(
        gameDetail =>
          gameDetail.poolModes.includes(this.selectedPool.poolMode) &&
          gameDetail.joinModes.includes(this.selectedPool.joinMode),
      );

      let gameDetailsFields = gameDetailsFormat.fields.map(gameDetailField => ({
        ...gameDetailField,
        value: selectedPoolGameDetails ? selectedPoolGameDetails[gameDetailField.key] : '',
      }));

      if (gameDetailsFields.modeName) {
        gameDetailsFields[0].value = String(gameDetailsFields.modeName);
      }

      if (this.selectedPool.game === 5) {
        gameDetailsFields[4].value = selectedPoolGameDetails.specRoundData?.roundDurationType;
        gameDetailsFields[5].value = selectedPoolGameDetails.specRoundData?.gralDuration;

        gameDetailsFields.splice(3, 1);
      }

      const gameDetails = {
        id: this.selectedPool.game,
        fields: gameDetailsFields,
      };

      return gameDetails;
    },
    async updateTemplates() {
      const data = {
        game: this.pool.game?.id,
        poolMode: this.pool.poolMode,
      };

      const templateResponse = await this.$store.dispatch('templates/getTemplates', data);
      this.templates = templateResponse;
    },
    async savePool(update) {
      try {
        const validFields = this.validateGeneralFields() && this.validateDetailsFields();

        if (!validFields) {
          return;
        }

        this.loading = true;

        const {
          description,
          detailHeaderBackImage,
          detailHeaderFrontImage,
          detailsTemplateId,
          doNotFinish,
          endDate,
          entrance,
          entranceTokenAddress,
          entranceTokenImage,
          entranceTokenName,
          entranceTokenSymbol,
          gameDetails,
          gameMode,
          id,
          isPaid,
          isPublished,
          joinBtnMode,
          joinMode,
          mainTemplateId,
          maxPlayers,
          minPlayers,
          name,
          poolMode,
          poolPrize,
          registrationEndDate,
          registrationStartDate,
          reportTemplateId,
          rules,
          showInHome,
          sponsored,
          startDate,
          template,
          tokenAddress,
          tokenImage,
          tokenName,
          tokenSymbol,
          winnersFees,
        } = this.pool;
        const gameDetailsObject = this.mapGameDetails(gameDetails);

        if (this.enableJoinReqData) {
          gameDetailsObject.joinReqData = this.joinReqData;
        }

        if (this.enableUserProfileValidation) {
          gameDetailsObject.userProfileValidation = this.userProfileValidation;
        }

        const pool = {
          description,
          doNotFinish,
          detailHeaderBackImage,
          detailHeaderFrontImage,
          detailsTemplateId: detailsTemplateId || '',
          endDate: moment(endDate, 'YYYY-MM-DD HH:mm:ss').valueOf(),
          entrance: Number(entrance),
          gameId: Number(this.pool.game.id),
          gameMode,
          gameDetails: gameDetailsObject,
          id,
          isPaid,
          isPublished,
          mainTemplateId: mainTemplateId || '',
          maxPlayers: Number(maxPlayers),
          minPlayers: Number(minPlayers),
          name,
          poolMode,
          joinBtnMode,
          joinMode,
          poolPrize: Number(poolPrize),
          registrationEndDate: moment(registrationEndDate, 'YYYY-MM-DD HH:mm:ss').valueOf(),
          registrationStartDate: moment(registrationStartDate, 'YYYY-MM-DD HH:mm:ss').valueOf(),
          reportTemplateId: reportTemplateId || '',
          rules,
          showInHome,
          sponsored,
          startDate: moment(startDate, 'YYYY-MM-DD HH:mm:ss').valueOf(),
          template,
          tokenAddress,
          tokenImage,
          tokenName,
          tokenSymbol,
          winnersFees: winnersFees.map(fee => Number(fee)),
        };

        if (entranceTokenName) {
          pool.entranceTokenAddress = entranceTokenAddress;
          pool.entranceTokenImage = entranceTokenImage;
          pool.entranceTokenName = entranceTokenName;
          pool.entranceTokenSymbol = entranceTokenSymbol;
        }

        if (update) {
          const poolId = await this.$store.dispatch('pools/updatePool', pool);

          if (poolId?.error) {
            await this.$notify({ type: 'danger', message: `Error updating pool: ${poolId?.message}` });

            this.loading = false;
            return;
          }

          await this.$notify({ type: 'success', message: `Pool ${poolId?.message} updated successfully!` });

          this.loading = false;
          return;
        }

        // new pool
        const poolId = await this.$store.dispatch('pools/createPool', pool);

        if (poolId?.error) {
          await this.$notify({ type: 'danger', message: `Error creating pool: ${poolId?.message}` });

          this.loading = false;
          return;
        }

        await this.$notify({ type: 'success', message: `Success on creating pool: ${poolId?.message}` });

        this.loading = false;
        this.$emit('created', true);
      } catch (error) {
        await this.$notify({ type: 'danger', message: `Error ${update ? 'updating' : 'creating'} pool: ${error}` });

        this.loading = false;
        return;
      }
    },
  },
  async mounted() {
    const games = [...this.$store.getters['games/games']];
    const joinBtnModes = [...this.$store.getters['pools/joinBtnModes']];
    const joinModes = [...this.$store.getters['pools/joinModes']];
    const poolModes = [...this.$store.getters['pools/poolModes']];
    const poolStatus = [...this.$store.getters['pools/poolStatus']];

    this.pool = { ...this.$store.getters['pools/pool'] };
    this.joinReqData = { ...this.$store.getters['pools/joinReqData'] };
    this.userProfileValidation = { ...this.$store.getters['pools/userProfileValidation'] };

    // update pool
    if (this.selectedPool != undefined) {
      const game = games.find(game => game.id === this.selectedPool.game);
      const gameDetails = this.mapGameDetailsForm(this.selectedPool.gameDetails);

      this.enableJoinReqData = this.selectedPool.gameDetails.joinReqData;
      this.enableUserProfileValidation = this.selectedPool.gameDetails.userProfileValidation;

      this.pool = {
        ...this.selectedPool,
        game,
        gameDetails,
      };

      await this.updateTemplates();

      return;
    }

    // new pool
    this.pool.game = games[0];
    this.pool.poolMode = poolModes[0];
    this.pool.joinMode = joinModes[0];
    this.pool.joinBtnMode = joinBtnModes[0];
    this.pool.poolStatus = poolStatus[0];
    this.pool.winnersFees = [''];
    this.pool.rules = [{}];
  },
};
</script>

<style>
.form-control:focus {
  border-color: #fff;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
