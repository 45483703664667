<template>
  <footer class="footer">
    <div class="container-fluid">
      <ul class="nav">
          <li class="nav-item">
            <a href="https://www.blast.gg" class="nav-link" target="_blank" rel="noopener">BLAST</a>
          </li>
      </ul>
      <div class="copyright">
        <i class="fa fa-copyright" style="font-weight: 200;"></i> {{ year }}. If you have any problem, please contact us through our
          <a
            href="https://discord.com/invite/3ZyJTT5tjt"
            class="font-weight-bold ml-1"
            target="_blank"
            rel="noopener"
            >discord</a
          >.
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    data() {
      return {
        year: new Date().getFullYear()
      }
    }
  };
</script>
<style>
</style>
