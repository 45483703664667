/* import the fontawesome */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { 
  faAngleDown, 
  faAngleUp, 
  faArrowRight, 
  faBell, 
  faChartLine, 
  faCheck, 
  faChevronLeft, 
  faChevronRight, 
  faClone, 
  faCopy,
  faFileCsv, 
  faFileInvoice, 
  faFilePen, 
  faFloppyDisk, 
  faGamepad, 
  faGears, 
  faInfo, 
  faLanguage,
  faList, 
  faMinus, 
  faPlay, 
  faPlus, 
  faRankingStar, 
  faRectangleAd, 
  faRectangleList, 
  faRightLeft, 
  faRuler, 
  faShapes, 
  faSitemap, 
  faStop, 
  faTrash, 
  faUser, 
  faUserGroup, 
  faWallet, 
  faXmark, 
} from '@fortawesome/free-solid-svg-icons'

// Base components
import BaseAlert from "@/components/Base/BaseAlert.vue";
import BaseButton from "@/components/Base/BaseButton";
import BaseCheckbox from "@/components/Base/BaseCheckbox.vue";
import BaseDropdown from "@/components/Base/BaseDropdown.vue";
import BaseInput from "@/components/Base/Inputs/BaseInput.vue";
import BaseNav from "@/components/Base/BaseNav";
import BaseRadio from "@/components/Base/BaseRadio.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseCard from "@/components/Base/Cards/BaseCard.vue";
import StatsCard from "@/components/Base/Cards/StatsCard.vue";
import BasePagination from '@/components/Base/Pagination/BasePagination';
import BaseModal from "@/components/Base/BaseModal.vue";

// Pools components
import BracketsTable from "./Pools/BracketsTable.vue";
import LeaderboardTable from "./Pools/LeaderboardTable.vue";
import PoolDescription from "./Pools/PoolDescription.vue";
import BracketResults from "./Pools/Results/BracketResults.vue";
import LeaderboardResults from "./Pools/Results/LeaderboardResults.vue";
import GenericReport from "./Pools/Results/Reports/GenericReport.vue";
import LeaderReport from "./Pools/Results/Reports/LeaderReport.vue";
import MovePoolPlayers from "./Pools/MovePoolPlayers.vue";
import ScoreReport from "./Pools/Results/Reports/ScoreReport.vue";
import PoolForm from "./Pools/Form/PoolForm.vue";
import General from "./Pools/Form/General.vue";
import Tokens from "./Pools/Form/Tokens.vue";
import Details from "./Pools/Form/Details.vue";
import Report from "./Pools/Results/Reports/Report.vue";
import Rules from "./Pools/Form/Rules.vue";
import Wizard from "./Pools/Form/Wizard.vue";
import Manager from "./Pools/Form/Manager.vue";

// Advertisements components
import AdForm from "./Advertisements/AdForm.vue";

// Organizations components
import OrganizationDescription from "@/components/Organizations/OrganizationDescription.vue";
import OrganizationForm from "@/components/Organizations/OrganizationForm.vue";

// Templates components
import DetailsComponent from "@/components/Templates/DetailsComponent.vue";
import MainComponent from "@/components/Templates/MainComponent.vue";
import ReportComponent from "@/components/Templates/ReportComponent.vue";

// Teams components
import TeamDescription from "@/components/Teams/TeamDescription.vue";

// Translations components
import TranslationForm from "@/components/Translations/TranslationForm.vue";

import CloseButton from "@/components/CloseButton";
import SidebarPlugin from "@/components/SidebarPlugin";
import LoadingComponent from "@/components/LoaderSpinner.vue";

// User components
import UserDescription from "@/components/Users/UserDescription.vue";


/* add icons to the library */
library.add(
  faAngleDown, 
  faAngleUp, 
  faArrowRight, 
  faBell, 
  faChartLine, 
  faCheck, 
  faChevronLeft, 
  faChevronRight, 
  faClone, 
  faCopy,
  faFileCsv, 
  faFileInvoice, 
  faFilePen, 
  faFloppyDisk, 
  faGamepad, 
  faGears, 
  faInfo, 
  faLanguage,
  faList, 
  faMinus, 
  faPlay, 
  faPlus, 
  faRankingStar, 
  faRectangleAd, 
  faRectangleList, 
  faRuler, 
  faShapes, 
  faSitemap,
  faStop, 
  faTrash, 
  faUser, 
  faUserGroup,
  faWallet, 
  faXmark,
  faArrowRight,
  faRightLeft
);


export {
  AdForm,
  BaseAlert,
  BaseButton,
  BaseCard,
  BaseCheckbox,
  BaseDropdown,
  BaseInput,
  BaseModal,
  BaseNav,
  BasePagination,
  BaseRadio,
  BaseTable,
  BracketResults,
  BracketsTable,
  CloseButton,
  Details,
  DetailsComponent,
  FontAwesomeIcon,
  General,
  GenericReport,
  LeaderboardResults,
  LeaderboardTable,
  LeaderReport,
  LoadingComponent,
  MainComponent,
  Manager,
  MovePoolPlayers,
  OrganizationDescription,
  OrganizationForm,
  PoolDescription,
  PoolForm,
  Report,
  ReportComponent,
  Rules,
  ScoreReport,
  SidebarPlugin,
  StatsCard,
  TeamDescription,
  Tokens,
  TranslationForm,
  UserDescription,
  Wizard,
};
