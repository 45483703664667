<template>
  <component :is="tag" @click.native="hideSidebar" class="nav-item clean-active" v-bind="$attrs" tag="li">
    <a class="nav-link col-12 row">
      <slot>
        <font-awesome-icon v-if="icon" :icon="icon" class="col-1" style="font-size: 16px" />
        <span class="col-10" style="font-size: 11px">{{ name }}</span>
      </slot>
    </a>
  </component>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'sidebar-link',
  components: {
    FontAwesomeIcon,
  },
  inheritAttrs: false,
  inject: {
    autoClose: {
      default: true,
    },
    addLink: {
      default: () => {},
    },
    removeLink: {
      default: () => {},
    },
  },
  props: {
    name: String,
    icon: String,
    tag: {
      type: String,
      default: 'router-link',
    },
  },
  methods: {
    hideSidebar() {
      if (this.autoClose) {
        this.$sidebar.displaySidebar(false);
      }
    },
    isActive() {
      return this.$el.classList.contains('active');
    },
  },
  mounted() {
    if (this.addLink) {
      this.addLink(this);
    }
  },
  beforeDestroy() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  },
};
</script>

<style>
ul {
  list-style-type: none !important;
}
</style>
