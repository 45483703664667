import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from 'vue-router-prefetch'
import App from "./App";
import router from "./router/index";
import store from "./store";

import Dashboard from "./plugins/dashboard";
import i18n from "./i18n";
import VueMeta from 'vue-meta';

Vue.use(Dashboard);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(VueMeta, { keyName: 'head' })

/* eslint-disable no-new */
new Vue({
  store: store,
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
