<template>
  <BaseCard>
    <div>
      <div class="row">
        <div class="col-5" v-if="$slots.header">
          <slot name="header"></slot>
        </div>
        <div class="col-7" v-if="$slots.content">
          <slot name="content"></slot>
        </div>
      </div>
      <div v-if="$slots.footer">
        <hr />
        <slot name="footer"></slot>
      </div>
    </div>
  </BaseCard>
</template>
<script>
import { BaseCard } from '@/components';

export default {
  name: 'stats-card',
  components: {
    BaseCard,
  },
};
</script>
<style></style>
