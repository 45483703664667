<template>
  <div>
    <form>
      <div class="p-4 mb-4">
        <div class="col-12 row">
          <div class="col-4 form-group component-section">
            <label class="control-label form-text">Component</label>
            <select v-model="component.key" class="form-control" @change="changeComponent(component.key)">
              <option class="color" v-for="(item, index) in this.components" :value="item" :key="index">
                {{ item }}
              </option>
            </select>
          </div>
        </div>

        <div v-if="hasComponentValue" class="col-12 form-group component-section">
          <label class="control-label form-text">Value</label>

          <template v-if="isFullInput">
            <div v-if="component.value.length" class="col-12 form-group">
              <label class="control-label form-text">Label</label>
              <input
                v-model="component.value[0].value"
                aria-describedby="addon-right addon-left"
                placeholder="Component label"
                class="form-control"
              />
            </div>

            <div v-if="component.value.length" class="col-12 form-group">
              <label class="control-label form-text">Value</label>
              <input
                v-model="component.value[1].value"
                aria-describedby="addon-right addon-left"
                placeholder="Component value"
                class="form-control"
              />
            </div>

            <div v-if="component.value.length" class="col-12 form-group">
              <label class="control-label form-text">Placeholder</label>
              <input
                v-model="component.value[1].placeholder"
                aria-describedby="addon-right addon-left"
                placeholder="Component placeholder"
                class="form-control"
              />
            </div>
          </template>

          <template v-if="isSelect">
            <input
              v-model="component.value"
              aria-describedby="addon-right addon-left"
              placeholder="Component value"
              class="form-control"
            />
          </template>
        </div>

        <div class="col-12 form-check">
          <label @click="component.visible = !component.visible" for="visible" class="form-check-label">
            <input v-model="component.visible" type="checkbox" class="form-check-input" />
            <span class="form-check-sign"></span>
            <span class="form-text">Visible</span>
          </label>
        </div>

        <div class="col-12 form-check">
          <label @click="component.required = !component.required" for="visible" class="form-check-label">
            <input v-model="component.required" type="checkbox" class="form-check-input" />
            <span class="form-check-sign"></span>
            <span class="form-text">Required</span>
          </label>
        </div>

        <div class="col-12 mt-3 row justify-content-end">
          <base-button
            simple
            type="primary"
            class="my-3 center"
            style="cursor: pointer width: 60px; height: 50px;"
            @click="createComponent()"
          >
            <p class="center">Create</p>
          </base-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      component: {
        key: null,
        value: null,
        visible: true,
        required: false,
      },
      components: [],
      componentsHasValue: [],
    };
  },
  computed: {
    hasComponentValue() {
      return this.componentsHasValue?.includes(this.component.key);
    },
    isFullInput() {
      return this.component.key === 'full-input';
    },
    isSelect() {
      return this.component.key === 'select';
    },
  },
  watch: {},
  mounted() {
    this.components = [...this.$store.getters['templates/reportComponents']];
    this.componentsHasValue = [...this.$store.getters['templates/componentsHasValue']];

    this.component = { key: this.components[0], value: '', style: [], visible: true, required: false };
    this.changeComponent();
  },
  methods: {
    changeComponent() {
      if (this.isFullInput) {
        this.component.value = [
          { key: 'label', value: '' },
          { key: 'input', value: '', type: 'text' },
        ];

        return;
      }

      this.component.value = '';

      return;
    },
    createComponent() {
      const newComponent = structuredClone(this.component);

      this.$emit('createComponent', newComponent);
    },
  },
};
</script>

<style lang="" scoped></style>
