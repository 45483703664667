import axios from "axios";
import router from "@/router";

const url = process.env.VUE_APP_BLAST_API_BASE_URL;

export default {
  state: {
    isAuthenticated: localStorage.getItem("auth_access_token") !== null,
    token: ''
  },

  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    token(state) {
      return state.token;
    }
  },

  mutations: {
    isAuthenticated(state, payload) {
      state.isAuthenticated = payload.isAuthenticated;
    },
    token(state, payload) {
      state.token = payload.token;
    }
  },

  actions: {
    async login({ commit }, data) {
      try {
        const { token } = data;

        if (!token) {
          throw 'Invalid token!';
        }

        const response = await axios.post(`${url}/admin/signIn/`, data);
        const { error, token: googleToken } = response.data;

        if (error) {
          throw error;
        }

        localStorage.setItem("auth_access_token", googleToken);
        commit('isAuthenticated', { isAuthenticated: true });
        commit('token', { token: token });

        router.push({path: "/dashboard"});
      } catch (error) {
        return error;
      }
    },

    register(context, payload) {

    },

    logout({ commit }) {
      localStorage.removeItem("auth_access_token");
      commit('isAuthenticated', { isAuthenticated: false });
      router.push({path: "/login"});
    }
  }
};
