import { render, staticRenderFns } from "./GenericReport.vue?vue&type=template&id=64129de2&"
import script from "./GenericReport.vue?vue&type=script&lang=js&"
export * from "./GenericReport.vue?vue&type=script&lang=js&"
import style0 from "./GenericReport.vue?vue&type=style&index=0&id=64129de2&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports