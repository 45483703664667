import axios from "axios";

import { storeHelper } from "../helpers";

const url = process.env.VUE_APP_BLAST_API_BASE_URL;

const state = {
  accounts: []
};

const mutations = {
  SET_ACCOUNTS: (state, accounts) => {
    state.accounts = accounts;
  },
}

const getters = {
  accounts: state => state.accounts
};

const actions = {
  async getAccounts({ commit }, data) {
    try {
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
      
      const accounts = await axios.get(`${url}/admin/account`, config);
      const { data: accountsData } = accounts;
  
      commit('SET_ACCOUNTS', accountsData);
  
      return accountsData;
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async updateAccount({ commit }, data) {
    try {
      const { accountId, username } = data;
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
  
      const accountResponse = await axios.put(`${url}/admin/account/${accountId}/${username}`, data, config);
      const { data: accountData } = accountResponse;
  
      return { error: false, message: accountData }; 
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
};

const accounts = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};

export default accounts;
