<template>
  <div class="row d-flex justify-content-center">
    <div class="col-md-10 my-4">
      <div class="wizard">
        <div class="wizard-inner pb-3">
          <div class="connecting-line"></div>
          <ul class="nav nav-tabs" role="tablist">
            <li role="presentation" :class="{ active: step > 1, current: step === 1 }">
              <a data-toggle="tab" role="tab"
                ><span class="round-tab">
                  <font-awesome-icon icon="fa-rectangle-list" />
                </span>
                <i>General</i></a
              >
            </li>
            <li role="presentation" :class="{ active: step > 2, current: step === 2 }">
              <a data-toggle="tab" role="tab"
                ><span class="round-tab">
                  <font-awesome-icon icon="fa-wallet" />
                </span>
                <i>Tokens</i></a
              >
            </li>
            <li role="presentation" :class="{ active: step > 3, current: step === 3 }">
              <a data-toggle="tab" role="tab"
                ><span class="round-tab">
                  <font-awesome-icon icon="fa-solid fa-info" />
                </span>
                <i>Details</i></a
              >
            </li>
            <li role="presentation" :class="{ current: step === 4 }">
              <a data-toggle="tab" role="tab"
                ><span class="round-tab">
                  <font-awesome-icon icon="fa-list" />
                </span>
                <i>Rules</i></a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: '',
  components: {
    FontAwesomeIcon,
  },
  mixins: [],
  props: {
    step: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped>
.wizard .nav-tabs {
  position: relative;
  margin-bottom: 0;
  border-bottom-color: transparent;
}

.wizard > div.wizard-inner {
  position: relative;
  margin-bottom: 50px;
  text-align: center;
}

.connecting-line {
  height: 1px;
  background: #e0e0e0;
  position: absolute;
  width: 73%;
  margin: 0 auto;
  left: 15px;
  right: 0;
  top: 22px;
  z-index: 1;
}

span.round-tab {
  width: 45px;
  height: 45px;
  line-height: 42px;
  display: inline-block;
  border-radius: 50%;
  background: #ddd;
  z-index: 2;
  position: absolute;
  text-align: center;
  font-size: 16px;
  color: #0e214b;
  font-weight: 500;
  border: 1px solid #dddddd;
}

.wizard li.current span.round-tab {
  background: rgb(32, 19, 102);
  color: #fff;
  border-color: #fff;
}

.wizard li.active span.round-tab {
  background: rgb(32, 19, 102);
  color: #fff;
  border-color: rgb(32, 19, 102);
}
.wizard .nav-tabs > li.active > a i {
  color: #fff;
}

.wizard .nav-tabs > li {
  width: 25%;
}

.wizard .nav-tabs > li a {
  width: 30px;
  height: 30px;
  margin: 20px auto;
  border-radius: 100%;
  padding: 0;
  background-color: transparent;
  position: relative;
  top: 0;
}
.wizard .nav-tabs > li a i {
  position: absolute;
  top: -15px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-weight: 700;
  right: 25px;
  color: #dddddd;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
