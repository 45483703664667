<template>
  <div class="my-4">
    <div class="p-4" style="border: solid; border-color: #344675; border-radius: 12px; border-width: thin">
      <div class="col-12 row">
        <div class="col-6 form-group">
          <label class="control-label form-text">Name *</label>
          <small v-if="!nameValid" class="col-12 form-text" style="color: #f66555">name can't be empty.</small>
          <input
            v-model="organization.name"
            aria-describedby="addon-right addon-left"
            placeholder="Organization name"
            class="form-control"
          />
        </div>

        <div class="col-6 form-group">
          <label class="control-label form-text">Url param</label>
          <input
            v-model="organization.urlParam"
            aria-describedby="addon-right addon-left"
            placeholder="Organization's path"
            class="form-control"
          />
        </div>

        <div class="col-6 form-group">
          <label class="control-label form-text">
            Referral code
            <font-awesome-icon
              v-if="organizationURL"
              @click="copyToClipboard(organizationURL)"
              icon="fa-copy"
              class="pointer"
            />
          </label>
          <input
            v-model="organization.refCode"
            aria-describedby="addon-right addon-left"
            placeholder="referral code to join by"
            class="form-control"
          />
        </div>

        <div class="col-6 form-group">
          <label class="control-label form-text">Language</label>
          <input
            v-model="organization.language"
            aria-describedby="addon-right addon-left"
            placeholder="EN"
            class="form-control"
          />
        </div>

        <div class="col-6 form-group">
          <label class="control-label form-text">Select the organization's owner *</label>
          <input
            v-model="ownerSearch"
            type="text"
            @keyup.enter="searchUsers()"
            aria-describedby="addon-right addon-left"
            placeholder="search a user as owner..."
            class="col-6 form-control d-inline mb-3 border-0"
          />
          <select class="col-6 d-inline form-control" v-model="selectedUser" :disabled="loading">
            <option value="null" disabled selected>Select a user</option>
            <option class="color" v-for="user in users" :key="user.id" :value="user">
              {{ user.nick }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div
      v-if="!hasOrganization"
      class="p-4 my-3"
      style="border: solid; border-color: #344675; border-radius: 12px; border-width: thin"
    >
      <div class="col-12 row">
        <div class="col-12 row mt-2 ml-2 form-group">
          <div class="col-8 form-check">
            <label for="is-published" class="form-check-label">
              <input v-model="userPermissions.create" type="checkbox" class="form-check-input" />
              <span class="form-check-sign"></span>
              <span @click="userPermissions.create = !userPermissions.create" class="form-text">CREATE</span>
            </label>
          </div>

          <div class="col-8 form-check">
            <label for="do-not-finish" class="form-check-label">
              <input v-model="userPermissions.read" type="checkbox" class="form-check-input" />
              <span class="form-check-sign"></span>
              <span @click="userPermissions.read = !userPermissions.read" class="form-text">READ</span>
            </label>
          </div>

          <div class="col-8 form-check">
            <label for="is-paid" class="form-check-label">
              <input v-model="userPermissions.update" type="checkbox" class="form-check-input" />
              <span class="form-check-sign"></span>
              <span @click="userPermissions.update = !userPermissions.update" class="form-text">UPDATE</span>
            </label>
          </div>

          <div class="col-8 form-check">
            <label for="is-paid" class="form-check-label">
              <input v-model="userPermissions.delete" type="checkbox" class="form-check-input" />
              <span class="form-check-sign"></span>
              <span @click="userPermissions.delete = !userPermissions.delete" class="form-text">DELETE</span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 mt-3 row">
      <div class="col-8"></div>

      <div class="col-4 mt-3 row justify-content-end">
        <base-button
          v-if="!hasOrganization"
          simple
          type="primary"
          class="my-3 center"
          style="cursor: pointer width: 60px; height: 50px;"
          @click="saveOrganization(false)"
          :disabled="loading"
        >
          <span v-if="loading" class="center" style="width: 100%; height: 100%">
            <LoadingComponent />
          </span>
          <span v-else class="center">create</span>
        </base-button>
        <base-button
          v-if="hasOrganization"
          simple
          type="primary"
          class="my-3 center"
          style="cursor: pointer width: 60px; height: 50px;"
          @click="saveOrganization(true)"
          :disabled="loading"
        >
          <span v-if="loading" class="center" style="width: 100%; height: 100%">
            <LoadingComponent />
          </span>
          <span v-else class="center">update</span>
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import LoadingComponent from '@/components/LoaderSpinner';

export default {
  components: {
    FontAwesomeIcon,
    LoadingComponent,
  },
  props: {
    selectedOrganization: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: true,
      nameValid: true,
      organization: {},
      ownerSearch: '',
      users: [],
      selectedUser: {},
      userPermissions: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
    };
  },
  computed: {
    hasOrganization() {
      return this.selectedOrganization && Object.keys(this.selectedOrganization).length > 0;
    },
    organizationURL() {
      const appBaseUrl = process.env.VUE_APP_BLAST_BASE_URL;
      const organizationRefURL = `${appBaseUrl}/?ref=`;

      return this.organization.refCode ? `${organizationRefURL}${this.organization.refCode}` : '';
    },
  },
  watch: {},
  methods: {
    async saveOrganization(update) {
      this.loading = true;

      const { language, name, refCode, urlParam } = this.organization;
      const { id: owner } = this.selectedUser;

      const organization = {
        language,
        name,
        refCode,
        urlParam,
        owner,
      };

      if (update) {
        organization.id = this.organization.id;

        const organizationResponse = await this.$store.dispatch('organizations/updateOrganization', organization);
        const { error: organizationError, message: organizationMessage } = organizationResponse;

        if (organizationError) {
          await this.$notify({
            type: 'danger',
            message: `Error creating organization: ${organizationMessage}`,
          });

          this.loading = false;
          return;
        }

        await this.$notify({ type: 'success', message: `Success on updating organization: ${organizationMessage}` });

        this.loading = false;

        return;
      }

      // new organization
      organization.userPermissions = this.userPermissions;

      const organizationResponse = await this.$store.dispatch('organizations/createOrganization', organization);
      const { error: organizationError, message: organizationMessage } = organizationResponse;

      if (organizationError) {
        await this.$notify({
          type: 'danger',
          message: `Error creating organization: ${organizationMessage}`,
        });

        this.loading = false;
        return;
      }

      await this.$notify({ type: 'success', message: `Success on creating organization: ${organizationMessage}` });

      this.loading = false;
    },
    async searchUsers() {
      this.loading = true;

      const data = { searchQuery: this.ownerSearch };
      const usersResponse = await this.$store.dispatch('users/searchUsers', data);
      const { error: usersError, message: usersMessage } = usersResponse;

      if (usersError) {
        await this.$notify({
          type: 'danger',
          message: `Error getting users: ${usersMessage}`,
        });

        this.loading = false;
        return;
      }

      this.users = this.$store.getters['users/users'];
      this.loading = false;
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);

      this.$notify({ type: 'success', message: `${text} copied to clipboard!` });
    },
  },
  mounted() {
    this.loading = true;

    // update organization
    if (this.hasOrganization) {
      let selectedUser = null;
      const { owner, users: organizationUsers } = this.selectedOrganization;
      const ownerData = organizationUsers.find(organizationUser => organizationUser.userId === owner);

      if (ownerData) {
        const { userNick, userId } = ownerData;
        selectedUser = { nick: userNick, id: userId };

        this.users = [selectedUser];
        this.selectedUser = selectedUser;
      }

      this.organization = this.selectedOrganization;

      this.loading = false;

      return;
    }

    this.organization.language = 'EN';

    this.loading = false;
  },
};
</script>

<style lang="" scoped></style>
