<template>
  <div v-if="show" class="tooltip-container">
    <div class="tooltip row">
      <p class="text mx-3">{{ text }}</p>
      <span @click="handleAccept" class="pointer px-1" style="font-size: 1em; color: #00f2c3">
        <font-awesome-icon icon="fa-solid fa-check" />
      </span>
      <span @click="handleDeny" class="pointer px-1" style="font-size: 1em; color: #f5503d">
        <font-awesome-icon icon="fa-solid fa-xmark" />
      </span>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  components: {
    FontAwesomeIcon,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
      description: 'text Required for the ToolTip',
    },
  },
  methods: {
    handleAccept() {
      this.$emit('accept');
    },
    handleDeny() {
      this.$emit('deny');
    },
  },
};
</script>

<style scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
}
.tooltip-container .tooltip {
  opacity: 1;
}
.tooltip {
  color: #ffffff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  bottom: 20px;
  right: -30px;
  width: 350px;

  opacity: 0;
  transition: opacity 1s;

  /* position: absolute; */
  z-index: 1;

  background: #000000;
}
.text {
  border-width: 5px;
  float: left;
}
.pointer {
  cursor: pointer;
}
</style>
