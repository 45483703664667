<template>
  <div class="p-4 mb-4" style="border: solid; border-color: #344675; border-radius: 12px; border-width: thin">
    <div class="col-12 row">
      <label class="col-10 control-label form-text">Rules</label>
      <label class="col-1 control-label" style="cursor: pointer" @click="addRule()">
        <font-awesome-icon icon="fa-plus" class="form-text" />
      </label>
      <label class="col-1 control-label" style="cursor: pointer" @click="removeRule()">
        <font-awesome-icon icon="fa-minus" class="form-text" />
      </label>
    </div>

    <div v-for="(item, index) in this.pool.rules" :key="index" class="col-12 m-4 row">
      <div class="col-12 row form-group">
        <input
          v-model="pool.rules[index].title"
          aria-describedby="addon-right addon-left"
          placeholder="rule title"
          class="col-6 my-3 form-control form-text"
        />

        <vue-editor v-model="pool.rules[index].content" :editor-toolbar="customization" class="col-12 mb-3" />
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VueEditor } from 'vue2-editor';

export default {
  components: {
    FontAwesomeIcon,
    VueEditor,
  },
  props: {
    pool: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      customization: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']],
    };
  },
  methods: {
    addRule() {
      this.pool.rules.push({});
    },
    removeRule() {
      this.pool.rules.pop();
    },
  },
  mounted() {},
};
</script>
<style lang="" scoped></style>
