<template>
  <div v-if="!selectedPool?.players?.data?.length">
    <p class="text-center p-3">There are no players for this pool</p>
  </div>
  <div v-else class="col-12 row no-margins">
    <bracket-results 
      v-if="Object.keys(selectedMatch).length"
      :selectedPool="selectedPool"
      :player="selectedPlayer"
      :opponents="opponents"
      :match="selectedMatch"
      :poolId="selectedPool.id" 
      :page="selectedPage.id" 
      :branch="selectedBranch.id" 
      :round="selectedRound.id"
      :game="selectedPool.game"
      @changeResult="changeResult">
    </bracket-results>

    <swap-players 
      :showSwapPlayers="showSwapPlayers" 
      :poolPlayers="selectedPool.leaderboard"
      :selectedPlayer="selectedSwapPlayer"
      :loading="swapLoading"
      @swapPlayers="swapPlayers"> 
    </swap-players>

    <div class="col-12 row mt-3 mb-2 mx-2">
      <div class="col-10">
        <p>Brackets status: <b>{{ selectedPool?.poolStatus?.toLowerCase() }}</b></p>
        <div v-if="selectedRound?.poolStatus == 'RUNNING'" class="blob green"></div>
      </div>

      <div @click="exportCSV(selectedPool.id)" class="col-1 " style="font-size: 1.2em; color: #f4f5f7">
        <font-awesome-icon icon="fa-file-csv" class="pointer" />
      </div>
      <div @click="processBracketsRounds(selectedPool.id)" class="col-1" style="font-size: 1.2em; color: #f4f5f7">
        <font-awesome-icon icon="fa-gears" class="pointer" />
      </div>
    </div>

    <div class="col-12 row mt-2 mb-3 mx-2">
      <div class="col-10">
        <div class="blobs-container">
          <p>
            Round status: <b>{{ selectedRound?.status?.toLowerCase() }}</b>
            <div v-if="selectedRound?.status == 'ACTIVE'" class="blob green"></div>
            <div v-else class="blob red"></div>
          </p>
        </div>
      </div>
    </div>

    <div class="col-3 form-group mx-3">
      <label class="control-label">Page</label>
      <select v-model="selectedPage" @change="selectPage(selectedPage)" class="form-control">
        <option class="color" v-for="(item, index) in this.pages" :value="item" :key="index">{{ item.name }}</option>
      </select>
    </div>
    <div class="col-3 form-group mx-3">
      <label class="control-label">Branch</label>
      <select v-model="selectedBranch" @change="selectBranch(selectedBranch)" class="form-control">
        <option class="color" v-for="(item, index) in this.branches" :value="item" :key="index">{{ item.name }}</option>
      </select>
    </div>
    <div class="col-3 form-group mx-3">
      <label class="control-label">Round</label>
      <select v-model="selectedRound" @change="selectRound(selectedRound)" class="form-control">
        <option class="color" v-for="(item, index) in this.rounds" :value="item" :key="index">{{ item.name }}</option>
      </select>
    </div>

    <div class="col-12 my-4">
      <div class="col-12 mb-3">
        <div class="col-12 row">
          <div class="col-3 center">nick</div>
          <div class="col-3 center">address</div>
          <div class="col-2 center">results</div>
          <div class="col-2 center">status</div>
          <div class="col-2 center">actions</div>
        </div>
      </div>

      <div v-for="(match, matchIndex) in this.selectedRound?.matches" :index="matchIndex" class="col-12">
        <div v-for="playerIdx in playesrPerMatch" class="col-12 row player no-margins" style="color: #f4f5f7">
          <div class="col-3">{{ match[`p${playerIdx}Nickname`] ? match[`p${playerIdx}Nickname`] : '-' }}</div>
          <div class="col-3 center">{{ match[`p${playerIdx}Address`] ? match[`p${playerIdx}Address`] : '-' }}</div>
          <div class="col-2 center">
            <div v-for="result in match[`p${playerIdx}Results`]">
              <span v-if="result.result === 'W'" class="px-1" style="font-size: 1em; color: #00f2c3">
                <font-awesome-icon icon="fa-solid fa-check" />
              </span>
              <span v-else class="px-1" style="font-size: 1em; color: #f5503d">
                <font-awesome-icon icon="fa-solid fa-xmark" />
              </span>
            </div>
          </div>
          <div class="col-2 center">
            {{ playerIdx === 1 ? match.status?.toLowerCase() : '' }}
          </div>
          <div class="col-2 center">
            <font-awesome-icon
              icon="fa-solid fa-file-pen"
              @click="canSelfReporting(playerIdx, match) && openResults(matchIndex + 1, match, playerIdx)"
              class="pointer px-1"
              :class="{ disable: !canSelfReporting(playerIdx, match) }"
            />
            <ToolTip
              :show="selectedRemovePlayer == `${matchIndex + 1}-${playerIdx}`"
              @accept="canRemovePlayer(playerIdx, match) && removePlayerMatch(matchIndex + 1, playerIdx)"
              @deny="cleanRemovePlayer"
              text="are you sure you want to remove this player?"
            >
            </ToolTip>
            <font-awesome-icon
              icon="fa-solid fa-trash"
              @click="canRemovePlayer(playerIdx, match) && openRemoveConfirmation(matchIndex + 1, playerIdx)"
              class="pointer px-1"
              :class="{ disable: !canRemovePlayer(playerIdx, match) }"
            />
            <font-awesome-icon
              icon="fa-solid fa-right-left"
              @click="isFirstRound() && openSwapPlayers(matchIndex, playerIdx)"
              class="pointer px-1"
              :class="{ disable: !isFirstRound() }"
            />
            <ToolTip
              :show="selectedMovePlayer == `${matchIndex + 1}-${playerIdx}`"
              text="There is another player in the next round, are you sure you want to replace that player?"
              @accept="canMovePlayer(matchIndex + 1) && moveNextRound(matchIndex + 1, playerIdx, true)"
              @deny="cleanMovePlayer"
            >
            </ToolTip>
            <font-awesome-icon
              v-if="!isLastPage() || !isLastRound()"
              icon="fa-solid fa-arrow-right"
              @click="canMovePlayer(matchIndex + 1) && moveNextRound(matchIndex + 1, playerIdx, false)"
              class="pointer px-1"
              :class="{ disable: !canMovePlayer(matchIndex + 1) }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import BracketResults from '@/components/Pools/Results/BracketResults';
import SwapPlayers from '@/components/Pools/SwapPlayers';
import ToolTip from '../Tooltip/ToolTip.vue';

export default {
  components: {
    FontAwesomeIcon,
    BracketResults,
    SwapPlayers,
    ToolTip,
  },
  props: {
    selectedPool: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      branches: [],
      matches: [],
      pages: [],
      playesrPerMatch: [1, 2],
      rounds: [],
      showSwapPlayers: false,
      selectedBranch: {},
      selectedMatchId: 0,
      selectedPage: {},
      selectedPlayer: 1,
      selectedRound: {},
      selectedSwapPlayer: {},
      selectedMovePlayer: '',
      selectedRemovePlayer: '',
      selectedMatch: {},
      opponents: [],
      swapLoading: false,
    };
  },
  methods: {
    selectPage(page) {
      this.cleanRemovePlayer();
      this.cleanMovePlayer();
      this.selectedPage = page;
      this.branches = [];

      for (let i = 1; i <= page.branches; i++) {
        this.branches.push({ id: i, name: `branch ${i}` });
      }

      this.selectBranch(this.branches[0]);
    },
    async selectBranch(branch) {
      this.cleanRemovePlayer();
      this.cleanMovePlayer();
      this.selectedBranch = branch;

      const data = { poolId: this.selectedPool.id, pageId: this.selectedPage.id, branchId: branch.id };
      const poolPage = await this.$store.dispatch('pools/getPoolPage', data);

      this.rounds = poolPage?.rounds.map(round => ({
        id: round.id,
        name: `round ${round.id}`,
        matches: round.matches,
        status: round.status,
      }));

      const currentRound = this.selectedRound.id ? this.selectedRound.id - 1 : 0;
      this.selectRound(this.rounds[currentRound]);
    },
    selectRound(round) {
      this.cleanRemovePlayer();
      this.cleanMovePlayer();
      this.selectedRound = round;
    },
    openSwapPlayers(matchIdx, playerIdx) {
      const match = this.selectedRound?.matches[matchIdx];

      const playerId = match[`p${playerIdx}aTag`];
      const playerNick = match[`p${playerIdx}Nickname`];

      this.selectedSwapPlayer = { 
        id: playerId, 
        nick: playerNick, 
        branch: this.selectedBranch.id, 
        match: matchIdx + 1, 
        position: playerIdx 
      };
      this.showSwapPlayers = true;
    },
    async changeResult() {
      await this.selectBranch(this.selectedBranch);

      const matchId = this.selectedMatch.matchId;
      const match = this.selectedRound?.matches[this.selectedMatchId];

      this.openResults(matchId, match);
    },
    async nextRoundMatch(matchId) {
      const nextRoundMatchId = Math.round(matchId / 2);
      const isLastRound = this.isLastRound();

      // the next round is on the next page
      if (isLastRound) {
        const nextRoundMatchId = Math.round(this.selectedBranch.id / 2);
        const data = {
          poolId: this.selectedPool.id,
          pageId: this.selectedPage.id + 1,
          branchId: 1,
        };
        const nextPoolPage = await this.$store.dispatch('pools/getPoolPage', data);
        const nextRound = nextPoolPage.rounds[0]; // first round of the next page
        const nextRoundMatch = nextRound?.matches[nextRoundMatchId - 1];

        return nextRoundMatch;
      }

      const nextRound = this.rounds[this.selectedRound.id]; // current round + 1
      const nextRoundMatch = nextRound.matches[nextRoundMatchId - 1];

      return nextRoundMatch;
    },
    hasResults(results) {
      return results?.length;
    },
    isLastPage() {
      return this.pages.length === this.selectedPage.id;
    },
    isFirstRound() {
      return this.selectedRound.id === 1;
    },
    isLastRound() {
      return this.rounds.length === this.selectedRound.id;
    },
    isFinished(status) {
      return status === 'FINISHED';
    },
    isPlaying(status) {
      return status === 'PLAYING';
    },
    openResults(matchId, match, playerIdx) {
      const { p1aTag, p2aTag, p1Nickname, p2Nickname } = match;
      
      this.opponents = { p1Nickname, p2Nickname },
      this.selectedPlayer = { playerIdx };
      this.selectedMatch = { matchId, match };
    },
    closeResults() {
      this.selectedMatch = null;
    },
    openRemoveConfirmation(matchId, player) {
      this.selectedRemovePlayer = `${matchId}-${player}`;
    },
    cleanRemovePlayer() {
      this.selectedRemovePlayer = '';
    },
    openMoveConfirmation(matchId, player) {
      this.selectedMovePlayer = `${matchId}-${player}`;
    },
    cleanMovePlayer() {
      this.selectedMovePlayer = '';
    },
    canSelfReporting(player, match) {
      // return match.status !== 'FINISHED';
      return true;
    },
    canRemovePlayer(player, match) {
      const playerNickname = match[`p${player}Nickname`];
      const matchResults = match[`p${player}Results`];
      const matchStatus = match.status;

      return playerNickname && !this.hasResults(matchResults) && !this.isFinished(matchStatus);
    },
    canMovePlayer(matchId) {
      const nextRoundMatch = this.nextRoundMatch(matchId);
      const nextMatchFinished = nextRoundMatch.status === 'FINISHED';
      const hasSomePlayerResults = nextRoundMatch?.p1Results?.length || nextRoundMatch?.p2Results?.length;

      return !hasSomePlayerResults && nextRoundMatch.status !== 'FINISHED';
    },
    async hasNextRoundPlayer(matchId) {
      const nextRoundMatch = await this.nextRoundMatch(matchId);
      const isLastRound = this.isLastRound();
      let nextRoundPlayer = matchId % 2 === 1 ? 1 : 2;

      if (isLastRound) {
        nextRoundPlayer = this.selectedBranch.id % 2 === 1 ? 1 : 2;
      }

      const hasMatchPlayer = nextRoundMatch[`p${nextRoundPlayer}Address`];

      return hasMatchPlayer;
    },
    async removePlayerMatch(matchId, player) {
      const data = {
        poolId: this.selectedPool.id,
        pageId: this.selectedPage.id,
        branchId: this.selectedBranch.id,
        roundId: this.selectedRound.id,
        matchId,
        player,
      };

      this.cleanRemovePlayer();
      const removed = await this.$store.dispatch('pools/removePlayer', data);

      if (removed.error) {
        await this.$notify({
          type: 'danger',
          message: `Error removing player: ${removed.error}`,
        });

        return;
      }

      await this.$notify({
        type: 'success',
        message: `Player ${removed.message} removed successfully!`,
      });

      this.selectBranch(this.selectedBranch);
    },
    async moveNextRound(matchId, player, force) {
      if (!force) {
        const hasNextRoundPlayer = await this.hasNextRoundPlayer(matchId);
        this.selectedMatchId = this.rounds[this.selectedRound.id - 1].matches[matchId - 1];
        this.selectedPlayer = player;

        if (hasNextRoundPlayer) {
          this.openMoveConfirmation(matchId, player);
          return;
        }
      }

      const data = {
        poolId: this.selectedPool.id,
        pageId: this.selectedPage.id,
        branchId: this.selectedBranch.id,
        roundId: this.selectedRound.id,
        matchId,
        player,
      };

      this.cleanMovePlayer();
      const moved = await this.$store.dispatch('pools/movePlayerNextRound', data);

      if (moved.error) {
        await this.$notify({
          type: 'danger',
          message: `Error moving player to the nex round: ${moved.error}`,
        });

        return;
      }

      await this.$notify({
        type: 'success',
        message: `Player ${moved.message} moved to the next round successfully!`,
      });

      await this.$notify({
        type: 'primary',
        message: `If you need reflect the players changes now, you should run the brackets rounds process.`,
        timeout: 8000,
      });
    },
    async swapPlayers(playersToSwap) {
      const data = {
        poolId: this.selectedPool.id,
        playersToSwap
      }

      this.swapLoading = true;

      const swapped = await this.$store.dispatch('pools/swapPlayers', data);

      if (swapped.error) {
        await this.$notify({
          type: 'danger',
          message: `Error swapping player: ${swapped.error}`,
        });

        this.swapLoading = false;

        return;
      }

      await this.$notify({
        type: 'success',
        message: `Players ${playersToSwap[0].nick} & ${playersToSwap[1].nick} swapped successfully!`,
      });

      this.showSwapPlayers = false;
      this.swapLoading = false;

      await this.selectBranch(this.selectedBranch);
    },
    async exportCSV(poolId) {
      const data = { poolId };
      this.loading = true;

      const exported = await this.$store.dispatch('pools/exportCSV', data);

      if (exported.error) {
        await this.$notify({
          type: 'danger',
          message: `Error exporting CSV for pool ${poolId}: ${exported.message}`,
        });
        this.loading = false;

        return;
      }

      this.loading = false;
    },
    async processBracketsRounds(poolId) {
      const data = { poolId };
      this.loading = true;

      const processed = await this.$store.dispatch('pools/processBracketsRounds', data);

      if (processed.error) {
        await this.$notify({
          type: 'danger',
          message: `Error running process for pool ${poolId}: ${processed.message}`,
        });
        this.loading = false;

        return;
      }

      await this.$notify({
        type: 'success',
        message: `Success running process for pool ${poolId}: ${processed.message}`,
      });

      this.loading = false;
    },
    async updateRound(status) {
      const data = {
        poolId: this.selectedPool.id,
        pageId: this.selectedPage.id,
        branchId: this.selectedBranch.id,
        roundId: this.selectedRound.id,
        roundData: { status },
      };

      if (status == 'ACTIVE') {
        const timestamp = moment.now();
        data.roundData.startDate = timestamp;
        data.roundData.endDate = moment(timestamp).add(30,'minutes').unix();
      }

      if (status == 'FINISHED') {
        data.roundData.endDate = moment.now();
      }

      this.loading = true;
      const updated = await this.$store.dispatch('pools/updateBracketsRound', data);

      if (updated.error) {
        await this.$notify({
          type: 'danger',
          message: `Error trying to update round ${this.selectedRound.id}: ${updated.message}`,
        });
        this.loading = false;

        return;
      }

      this.selectedRound.status = updated.message.status;

      await this.$notify({
        type: 'success',
        message: `Success updating round ${this.selectedRound.id} with status: ${updated.message.status}`,
      });
      this.loading = false;
    },
  },
  mounted() {
    const branchPagesInfo = this.selectedPool.gameDetails.branchPagesInfo;
    const branchPages = branchPagesInfo ? Object.values(branchPagesInfo) : [];

    if (branchPages.length) {
      this.pages = branchPages.map((branchPage, i) => ({ id: i + 1, name: `page ${i + 1}`, branches: branchPage }));
      this.selectPage(this.pages[0]);
    }
  },
};
</script>

<style>
.center {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.pointer {
  cursor: pointer;
}

.disable {
  cursor: default !important;
  color: gray;
}

.player:nth-child(odd) {
  /* border-left: 1px solid #473b91;
  border-right: 1px solid #473b91;
  border-top: 1px solid #473b91; */
  background-color: rgb(28, 22, 68);
  border-radius: 6px 6px 0 0;
  margin: 0px;
  padding: 5px 0 5px 5px;
}

.player:nth-child(even) {
  /* border-left: 1px solid #473b91;
  border-right: 1px solid #473b91;
  border-bottom: 1px solid #473b91; */
  background-color: rgb(28, 22, 68);
  border-radius: 0 0 6px 6px;
  margin: 0 0 40px 0;
  padding: 5px 0 5px 5px;
}

.blobs-container {
  display: flex;
}

.blob {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  margin: 8px 15px 10px 15px;
  height: 10px;
  width: 10px;
  transform: scale(1);
  animation: pulse-black 2s infinite;
}

.blob.green {
  background: rgba(51, 217, 178, 1);
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}

.blob.red {
  background: rgba(255, 82, 82, 1);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse-red 2s infinite;
}

.color {
  color: #222a42;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }
}
</style>
