import axios from "axios";
import * as moment from "moment";

import { storeHelper } from "../helpers";


const url = process.env.VUE_APP_BLAST_API_BASE_URL;

const state = {
  pools: []
};

const mutations = {
  SET_POOLS: (state, pools) => {
    state.pools = pools;
  },
}

const getters = {
  pools: state => state.pools,
  pool: () => ({
    description: '',
    detailHeaderBackImage: '',
    detailHeaderFrontImage: '',
    detailsTemplate: null,
    doNotFinish: false,
    endDate: moment().add(1, 'day').format('YYYY-MM-DD hh:mm:ss'),
    entrance: 0,
    entranceTokenAddress: '',
    entranceTokenImage: '',
    entranceTokenName: '',
    entranceTokenSymbol: '',
    game: '',
    gameMode: 1,
    gameDetails: {
      modeName: '',
    },
    isPaid: false,
    isPublished: true,
    joinMode: '',
    mainTemplate: null,
    maxPlayers: 50,
    minPlayers: 3,
    name: '',
    poolMode: '',
    poolPrize: 0,
    registrationEndDate: moment().add(10, 'minutes').format('YYYY-MM-DD hh:mm:ss'),
    registrationStartDate: moment().format('YYYY-MM-DD hh:mm:ss'),
    reportTemplate: null,
    rules: [],
    showInHome: true,
    sponsored: null,
    startDate: moment().add(20, 'minutes').format('YYYY-MM-DD hh:mm:ss'),
    tokenAddress: '',
    tokenImage: '',
    tokenName: '',
    tokenSymbol: '',
    winnersFees: []
  }),
  poolStatus: () => [
    'NOT_STARTED',
    'ARCHIVED',
    'JOINING',
    'PROCESSING_REGISTERED_USERS',
    'RUNNING',
    'FINISHED_COLLECTING_DATA',
    'FINISHED',
    'CANCELED',
  ],
  poolModes: () => [
    'LEADERBOARD',
    'ROUND_ROBIN',
    'SINGLE_ELIMINATION',
    'BEST_OF_3',
    'BEST_OF_5',
  ],
  joinModes: () => [
    'SOLO',
    'TEAM',
    'NFTS',
  ],
  joinBtnModes: () => [
    'AUTO',
    'ENABLED',
    'DISABLED',
  ],
  joinReqData: () => ({
    title: 'Tournament Registration Info',
    subtitle: 'Provide your Axie Account in-game name and register your Axies',
    formFields: [
      {
        error: false,
        length: 100,
        options: [],
        placeholder: 'In Game Name',
        title: 'Username in Origin',
        type: 'string',
        vealue: ''
      },
      {
        error: false,
        length: 100,
        options: [],
        placeholder: '#11111, #22222, #33333...',
        title: 'Axie Ids (up to 10)',
        type: 'string',
        vealue: ''
      },
      {
        error: false,
        length: 100,
        options: [],
        placeholder: '@elonmusk',
        title: 'Twitter Handle',
        type: 'string',
        vealue: ''
      }
    ]
  }),
  userProfileValidation: () => ({
    title: 'User Information',
    subtitle: "Make sure that this are the Discord Account and Ronin Address that you'll use in this tournament",
    validateFields: [
      'DISCORD_ACCOUNT',
      'GAMING_ACCOUNT'
    ]
  }),
  gameDetails: () => [
    {
      poolModes: ['LEADERBOARD'],
      joinModes: ['SOLO'],
      fields: [
        {
          name: 'Mode name',
          key: 'modeName',
          value: 'Fortunes Keep Res Solos, Rebirth Reverse'
        },
      ]
    },
    {
      poolModes: ['LEADERBOARD'],
      joinModes: ['TEAM'],
      fields: [
        {
          name: 'Team users qty *',
          key: 'teamUsersQty',
          value: 5
        },
        {
          name: 'Mode name',
          key: 'modeName',
          value: 'Fortunes Keep Res Solos, Rebirth Reverse'
        },
      ]
    },
    {
      poolModes: ['ROUND_ROBIN'],
      joinModes: ['SOLO'],
      fields: [
        {
          name: 'Mode name',
          key: 'modeName',
          value: 'Fortunes Keep Res Solos, Rebirth Reverse'
        },
      ]
    },
    {
      poolModes: ['ROUND_ROBIN'],
      joinModes: ['TEAM'],
      fields: [
        {
          name: 'Team users qty *',
          key: 'teamUsersQty',
          value: 5
        },
        {
          name: 'Mode name',
          key: 'modeName',
          value: 'Fortunes Keep Res Solos, Rebirth Reverse'
        },
      ]
    },
    {
      poolModes: ['SINGLE_ELIMINATION', 'BEST_OF_3', 'BEST_OF_5'],
      joinModes: ['SOLO'],
      fields: [
        {
          name: 'Branch players qty *',
          key: 'branchPlayersQty',
          value: 16
        },
        {
          name: 'Match min duration time',
          key: 'matchMinDurationTime',
          value: '120'
        },
        {
          name: 'Round general duration',
          key: 'gralDuration',
          value: '2700'
        },
        {
          name: 'Round duration type',
          key: 'roundDurationType',
          value: 'DYNAMIC_FIXED'
        },
        {
          name: 'Tournament players quantity',
          key: 'tournamentPlayersQty',
          value: '32'
        },
        {
          name: 'Check in severe mode',
          key: 'checkInSevereMode',
          value: false,
          type: 'boolean'
        },
        {
          name: 'Eneable round duration check',
          key: 'enableRoundDurationCheck',
          value: false,
          type: 'boolean'
        },
        {
          name: 'Third place match',
          key: 'enableThirdPlaceMatch',
          value: true,
          type: 'boolean'
        },
      ]
    },
    {
      poolModes: ['SINGLE_ELIMINATION', 'BEST_OF_3', 'BEST_OF_5'],
      joinModes: ['TEAM'],
      fields: [
        {
          name: 'Team users qty *',
          key: 'teamUsersQty',
          value: 5
        },
        {
          name: 'Branch players qty *',
          key: 'branchPlayersQty',
          value: 16
        },
        {
          name: 'Match min duration time',
          key: 'matchMinDurationTime',
          value: '120'
        },
        {
          name: 'Round general duration',
          key: 'gralDuration',
          value: '2700'
        },
        {
          name: 'Round duration type',
          key: 'roundDurationType',
          value: 'DYNAMIC_FIXED'
        },
        {
          name: 'Tournament players quantity',
          key: 'tournamentPlayersQty',
          value: '32'
        },
        {
          name: 'Check in severe mode',
          key: 'checkInSevereMode',
          value: false,
          type: 'boolean'
        },
        {
          name: 'Eneable round duration check',
          key: 'enableRoundDurationCheck',
          value: false,
          type: 'boolean'
        },
        {
          name: 'Third place match',
          key: 'enableThirdPlaceMatch',
          value: true,
          type: 'boolean'
        },
      ]
    },
  ],
  goerliTokens: () => [
    {
      logoURI: "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/misc/tokens/tether-usdt.png",
      chainId: 5,
      address: "0x0000000000000000000000000000000000000000",
      name: "Goerli ETH",
      symbol: "GoerliETH",
      decimals: 18
    },
    {
      logoURI: "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/misc/tokens/tether-usdt.png",
      chainId: 5,
      address: "0x509Ee0d083DdF8AC028f2a56731412edD63223B9",
      name: "usdt",
      symbol: "USDT",
      decimals: 6
    }
  ],
  polygonTokens: () => [
    {
      logoURI: "https://storage.googleapis.com/dev-blast.appspot.com/assets/Misc/tokens/matic.png",
      chainId: 137,
      address: "0x0000000000000000000000000000000000000000",
      name: "Matic",
      symbol: "MATIC",
      decimals: 18
    },
    {
      logoURI: "https://storage.googleapis.com/blast-prod-1.appspot.com/assets/misc/tokens/tether-usdt.png",
      chainId: 137,
      address: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
      name: "usdt",
      symbol: "USDT",
      decimals: 6
    },
    {
      logoURI: "",
      chainId: 137,
      address: "0xb4b52a1aced3f02114c60970971CedA553F7e20D",
      name: "Kuz",
      symbol: "KUZ",
      decimals: 18
    },
    {
      logoURI: "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295",
      chainId: 137,
      address: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
      name: "Wrapped Ether",
      symbol: "wETH",
      decimals: 18
    },
    {
      logoURI: "https://assets.coingecko.com/coins/images/9956/thumb/4943.png?1636636734",
      chainId: 137,
      address: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
      name: "Dai Stablecoin",
      symbol: "DAI",
      decimals: 18
    },
    {
      logoURI: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
      chainId: 137,
      address: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6
    },
    {
      logoURI: "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
      chainId: 137,
      address: "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
      name: "Wrapped Bitcoin",
      symbol: "wBTC",
      decimals: 8
    }
  ],
};

const actions = {
  async createPool({ commit }, data) {
    try {
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
  
      const pool = await axios.post(`${url}/admin/pool`, data, config);
      const { data: poolId } = pool;
  
      return { error: false, message: poolId }; 
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async createInvitationalCode({ commit }, data) {
    try {
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };

      const invitationalCode = await axios.post(`${url}/admin/pool/invitationalCode`, data, config);
      const { data: invitationalCodeMsg } = invitationalCode;
      
      return { error: false, message: invitationalCodeMsg };
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async getPools({ commit }, data) {
    try {
      const limit = 10;
      const { page, status } = data;
      const queryParams = `?limit=${limit}&page=${page}&status=${status}`;
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
  
      const pools = await axios.get(`${url}/admin/pool${queryParams}`, config);
      const { data: poolsData } = pools;
  
      commit('SET_POOLS', poolsData);
  
      return poolsData; 
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async searchPools({ commit }, data) {
    try {
      const { searchQuery } = data;
      const queryParams = `?&search=${searchQuery}`;
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
  
      const pools = await axios.get(`${url}/admin/pool/search${queryParams}`, config);
      const { data: poolsData } = pools;
  
      commit('SET_POOLS', poolsData);
  
      return true;
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async getPool({ commit }, data) {
    try {
      const { poolId } = data; 
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
  
      const pool = await axios.get(`${url}/admin/pool/${poolId}`, config);
      const { data: poolData } = pool;
  
      return poolData;
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async getPoolPage({ commit }, data) {
    try {
      const { poolId, pageId, branchId } = data;
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
  
      const response = await axios.get(`${url}/admin/poolData/${poolId}/${pageId}/${branchId}`, config);
      const { data: bracketPage } = response;
  
      return bracketPage;
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async removePlayer({ commit }, data) {
    try {
      const { poolId, pageId, branchId, roundId, matchId, player } = data;
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };

      const response = await axios.delete(`${url}/admin/poolData/${poolId}/${pageId}/${branchId}/${roundId}/${matchId}/${player}`, config);
      const { data: playerData } = response;
      
      return { error: false, message: playerData.nickname };
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async movePlayerNextRound({ commit }, data) {
    try {
      const { poolId, pageId, branchId } = data;
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };

      const response = await axios.put(`${url}/admin/poolData/${poolId}/${pageId}/${branchId}`, data, config);
      const { data: matchData } = response;
      
      return { error: false, message: matchData.winner };
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async updatePool({ commit }, data) {
    try {
      const { id: poolId } = data;
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
  
      const response = await axios.put(`${url}/admin/pool/${poolId}`, data, config);
      const { data: resData } = response;
  
      return { error: false, message: poolId }; 
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async createClonePool({ commit }, data) {
    try {
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };

      const clonePoolResponse = await axios.post(`${url}/admin/clone-pool`, data, config);
      const { data: clonePoolData } = clonePoolResponse;
      return { error: false, message: clonePoolData };
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async createReport({ commit }, data) {
    try {
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
  
      const reportResponse = await axios.post(`${url}/admin/pool/reportMatch`, data, config);
      const { data: reportData } = reportResponse;
  
      return { error: false, message: reportData }; 
    } catch (error) {
      console.log(error);
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async updateReport({ commit }, data) {
    try {
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
  
      const reportResponse = await axios.put(`${url}/admin/pool/reportMatch`, data, config);
      const { data: reportData } = reportResponse;
      const { data: report } = reportData;

      return { error: false, message: report }; 
    } catch (error) {
      console.log(error);
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async uploadFile({ commit }, data) {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/multipart/form-data',
          token: localStorage.getItem("auth_access_token")
        }
      };

      const headerFile = await axios.post(`${url}/admin/file`, data, config);
      const { data: headerImageData } = headerFile;
  
      return headerImageData;
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async deleteReport({ commit }, data) {
    try {
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };

      const reportResponse = await axios.post(`${url}/admin/pool/deleteReportMatch`, data, config);
      const { data: reportData } = reportResponse;
      const { data: report } = reportData;
      return { error: false, message: report };
    } catch (error) {
      console.log(error);
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },

  async initializeBrackets({ commit }) {
    try {
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };

      const response = await axios.get(`${url}/admin/initializeBrackets`, config);
      const { data: initializedData } = response;
        
      return { error: false, message: initializedData };
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async exportCSV({ commit }, data) {
    try {
      const { poolId } = data;
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };

      const response = await axios.get(`${url}/admin/bracketsCSV/${poolId}`, config);
      const { data: exportData } = response;

      let element = document.createElement('a');
      element.setAttribute('href', 'data:application/octet-stream;charset=utf-8,' + encodeURIComponent(exportData));
      element.setAttribute('download', 'axie.csv');

      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
        
      return { error: false, message: exportData };
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async processBracketsRounds({ commit }, data) {
    try {
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };

      const response = await axios.post(`${url}/admin/processBracketsRounds`, data, config);
      const { data: processData } = response;
        
      return { error: false, message: processData };
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async updateBracketsRound({ commit }, data) {
    try {
      const { poolId, pageId, branchId, roundId } = data;
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };

      const response = await axios.put(`${url}/admin/poolData/${poolId}/${pageId}/${branchId}/${roundId}`, data, config);
      const { data: roundData } = response;
      
      return { error: false, message: roundData };
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async removePlayer({ commit }, data) {
    try {
      const { poolId } = data;
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };

      const response = await axios.put(`${url}/admin/pool/${poolId}/leavePool`, data, config);
      const { data: roundData } = response;
      
      return { error: false, message: roundData };
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async movePlayers({ commit }, data) {
    try {
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };

      const response = await axios.post(`${url}/admin/pool/movePlayers`, data, config);
      const { data: movePlayersData } = response;
      
      return { error: false, message: movePlayersData };
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async swapPlayers({ commit }, data) {
    try {
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };

      const response = await axios.post(`${url}/admin/pool/swapPlayers`, data, config);
      const { data: swapPlayersData } = response;
      
      return { error: false, message: swapPlayersData };
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
};

const pools = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};

export default pools;
