<template>
  <div class="row">
    <div class="col-12">
      <BaseModal :show.sync="hasResults" class="modal-detail" id="resultsModal" :centered="false" :show-close="true">
        <template slot="header">
          <h3 class="modal-title">{{ statusReport.title }}</h3>
        </template>

        <Report
          :player="player"
          :opponents="opponents"
          :reportTemplate="selectedPool.reportTemplate"
          @updateReport="updateReport"
          @deleteReport="deleteReport"
          @reportMatch="reportMatch"
        ></Report>
      </BaseModal>
    </div>
  </div>
</template>

<script>
import { BaseModal } from '@/components';
import Report from '@/components/Pools/Results/Reports/Report';

export default {
  components: {
    BaseModal,
    Report,
  },
  props: {
    selectedPool: {
      type: Object,
      default: () => {},
    },
    playerIdx: {
      type: Number,
      default: 0,
    },
    opponents: {
      type: Object,
      default: () => {},
    },
    game: {
      type: Number,
    },
    match: {
      type: Object,
      default: () => {},
    },
    poolId: {
      type: Number,
    },
    page: {
      type: Number,
    },
    branch: {
      type: Number,
    },
    round: {
      type: Number,
    },
  },
  data() {
    return {
      loading: false,
      statusReport: {
        title: 'Results',
      },
      hasResults: false,
      player: {},
    };
  },
  watch: {
    match: function (matchValue, oldValue) {
      this.hasResults = matchValue && Object.keys(matchValue).length > 0;
    },
  },
  methods: {
    async reportMatch(reportData) {
      const data = {
        poolId: this.poolId,
        report: {
          reportData,
          pageId: this.page,
          branchId: this.branch,
          roundId: this.round,
          matchId: this.match.matchId,
        },
      };

      console.log({ data });

      this.loading = true;
      const reportResponse = await this.$store.dispatch('pools/createReport', data);

      if (reportResponse.error) {
        await this.$notify({
          type: 'danger',
          message: `Error trying to report the game`,
        });

        this.loading = false;
        return;
      }

      await this.$notify({
        type: 'success',
        message: `Success creating new report: ${reportResponse?.message}`,
      });

      this.loading = false;
      this.$emit('changeResult');
    },
    async updateReport(reportData) {
      const data = {
        poolId: this.poolId,
        report: {
          reportData,
          pageId: this.page,
          branchId: this.branch,
          roundId: this.round,
          matchId: this.match.matchId,
        },
      };

      this.loading = true;
      const reportResponse = await this.$store.dispatch('pools/updateReport', data);

      if (reportResponse.error) {
        await this.$notify({
          type: 'danger',
          message: `Error trying to update a report`,
        });

        this.loading = false;
        return;
      }

      await this.$notify({
        type: 'success',
        message: `Success updating game report: ${reportResponse?.message.reportData.gameIndex + 1}`,
      });

      this.loading = false;
      this.$emit('changeResult');
    },
    async deleteReport(reportData) {
      const data = {
        poolId: this.poolId,
        report: {
          pageId: this.page,
          branchId: this.branch,
          roundId: this.round,
          matchId: this.match.matchId,
          reportId: reportData?.matchId || '',
        },
      };

      this.loading = true;
      const reportResponse = await this.$store.dispatch('pools/deleteReport', data);

      if (reportResponse.error) {
        await this.$notify({
          type: 'danger',
          message: `Error trying to Delete a report`,
        });

        this.loading = false;
        return;
      }

      await this.$notify({
        type: 'success',
        message: `Success deleting game report`,
      });

      this.loading = false;
      this.$emit('changeResult');
    },
  },
  async mounted() {
    this.loading = true;

    const { playerIdx } = this.player;
    const { match } = this.match;

    this.player = {
      gamesData: match.gamesData,
      nickname: match[`p${playerIdx}Nickname`],
      aTag: match[`p${playerIdx}aTag`],
    };

    this.loading = false;
  },
};
</script>
