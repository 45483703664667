import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
import AuthLayout from '@/layout/dashboard/AuthLayout.vue';

// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

//import middleware
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";

const Login = () =>
  import(/* webpackChunkName: "pages" */ '@/pages/Login.vue');
const Register = () =>
  import(/* webpackChunkName: "pages" */ '@/pages/Register.vue');
const PasswordReset = () =>
  import(/* webpackChunkName: "password" */ "@/pages/Password/Reset.vue");
const PasswordEmail = () =>
  import(/* webpackChunkName: "password" */ "@/pages/Password/Email.vue");

// Admin pages
const Dashboard = () => import("@/pages/Dashboard.vue");
const Profile = () => import("@/pages/Profile.vue");
const Notifications = () => import("@/pages/Notifications.vue");
const Template = () => import("@/pages/Template.vue");
const Templates = () => import("@/pages/Templates.vue");
const Games = () => import("@/pages/Games.vue");
const Accounts = () => import("@/pages/Accounts.vue");
const Organizations = () => import("@/pages/Organizations.vue");
const Organization = () => import("@/pages/Organization.vue");
const Pool = () => import("@/pages/Pool.vue");
const Pools = () => import("@/pages/Pools.vue");
const Teams = () => import("@/pages/Teams.vue");
const Team = () => import("@/pages/Team.vue");
const Translations = () => import("@/pages/Translations.vue");
const Translation = () => import("@/pages/Translation.vue");
const Users = () => import("@/pages/Users.vue");
const User = () => import("@/pages/User.vue");
const Advertisements = () => import("@/pages/Advertisements.vue");
const ClonePool = () => import("@/pages/ClonePool.vue");
const MovePlayers = () => import("@/pages/MovePlayers.vue");

let authPages = {
  path: "/",
  redirect: "/login",
  component: AuthLayout,
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: { middleware: guest }
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
      meta: { middleware: guest }
    },
    {
      path: "/password/reset",
      name: "Password Reset",
      component: PasswordReset,
      meta: { middleware: guest }
    },
    {
      path: "/password/email",
      name: "Password Reset",
      component: PasswordEmail,
      meta: { middleware: guest }
    },
  ]
};

const routes = [
  authPages,
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: { middleware: auth }
      },
      {
        path: "profile",
        name: "profile",
        component: Profile,
        meta: { middleware: auth }
      },
      {
        path: "notifications",
        name: "notifications",
        component: Notifications,
        meta: { middleware: auth }
      },
      {
        path: "templates",
        name: "templates",
        component: Templates,
        meta: { middleware: auth }
      },
      {
        path: "template",
        name: "template/new",
        component: Template,
        meta: { middleware: auth }
      },
            {
        path: "template/:templateId",
        name: "template",
        component: Template,
        meta: { middleware: auth }
      },
      {
        path: "games",
        name: "games",
        component: Games,
        meta: { middleware: auth }
      },
      {
        path: "accounts",
        name: "accounts",
        component: Accounts,
        meta: { middleware: auth }
      },
      {
        path: "organization/:organizationId",
        name: "organization",
        component: Organization,
        meta: { middleware: auth }
      },
      {
        path: "organizations",
        name: "organizations",
        component: Organizations,
        meta: { middleware: auth }
      },
      {
        path: "pool",
        name: "pool/new",
        component: Pool,
        meta: { middleware: auth }
      },
      {
        path: "pool/clone",
        name: "Clone a Pool",
        component: ClonePool,
        meta: { middleware: auth }
      },
      {
        path: "pool/move-players",
        name: "Move pool players",
        component: MovePlayers,
        meta: { middleware: auth }
      },
      {
        path: "pool/:poolId",
        name: "pool",
        component: Pool,
        meta: { middleware: auth }
      },
      {
        path: "pools",
        name: "pools",
        component: Pools,
        meta: { middleware: auth }
      },
      {
        path: "teams",
        name: "teams",
        component: Teams,
        meta: { middleware: auth }
      },
      {
        path: "team/:teamId",
        name: "team",
        component: Team,
        meta: { middleware: auth }
      },
      {
        path: "translations",
        name: "translations",
        component: Translations,
        meta: { middleware: auth }
      },
      {
        path: "translation/:translationId",
        name: "translation",
        component: Translation,
        meta: { middleware: auth }
      },
      {
        path: "users",
        name: "users",
        component: Users,
        meta: { middleware: auth }
      },
      {
        path: "user/:userId",
        name: "user",
        component: User,
        meta: { middleware: auth }
      },
      {
        path: "advertisements",
        name: "advertisements",
        component: Advertisements,
        meta: { middleware: auth }
      },
    ]
  },
  { path: "*", component: NotFound },
];

export default routes;
